import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import H5Style from './../../../components/textComponents/H5Style';
import H6Style from '../../../components/textComponents/H6Style';
import PStyle from '../../../components/textComponents/PStyle';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '1px solid #E2E5FC',

  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(0),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderTop: '1px solid #E2E5FC',
  marginBottom: '50px',
}));

export default function CustomizedAccordions({ menus }) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    console.log('menus||', menus);
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {menus.map((menu, index) => {
        // console.log('i-----', menu);
        return Object.keys(menu.foods).map((key, i) => (
          <Accordion
            expanded={expanded === `panel${i + 1}`}
            onChange={handleChange(`panel${i + 1}`)}
          >
            <AccordionSummary
              aria-controls={`panel${i + 1}d-content`}
              id={`panel${i + 1}d-header`}
            >
              <H5Style>{key}</H5Style>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '20px',
                  justifyContent: 'space-between',
                }}
              >
                {menu.foods[key].map((item) => (
                  <Grid>
                    <H6Style
                      sx={{
                        color: '#3E3B4E',
                      }}
                    >
                      {/* Korv a la Forsell */}
                      {item.title}
                    </H6Style>
                    <PStyle
                      sx={{
                        my: '0px',
                      }}
                    >
                      {item.description}
                    </PStyle>
                    <H6Style
                      sx={{
                        // fontWeight: "bold",
                        color: '#3E3B4E',
                      }}
                    >
                      Pris: {item.price}
                    </H6Style>
                  </Grid>
                ))}
                {/* <Grid>
                  <H6Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                  >
                    Korv a la Forsell
                  </H6Style>
                  <PStyle
                    sx={{
                      my: '0px',
                    }}
                  >
                    Food meny description serveras med ris
                  </PStyle>
                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                    }}
                  >
                    Pris: 99 kr
                  </H6Style>
                </Grid>
                <Grid>
                  <H6Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                  >
                    Korv a la Forsell
                  </H6Style>
                  <PStyle
                    sx={{
                      my: '0px',
                    }}
                  >
                    Food meny description serveras med ris
                  </PStyle>
                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                    }}
                  >
                    Pris: 99 kr
                  </H6Style>
                </Grid>
                <Grid>
                  <H6Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                  >
                    Korv a la Forsell
                  </H6Style>
                  <PStyle
                    sx={{
                      my: '0px',
                    }}
                  >
                    Food meny description serveras med ris
                  </PStyle>
                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                    }}
                  >
                    Pris: 99 kr
                  </H6Style>
                </Grid>
                <Grid>
                  <H6Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                  >
                    Korv a la Forsell
                  </H6Style>
                  <PStyle
                    sx={{
                      my: '0px',
                    }}
                  >
                    Food meny description serveras med ris
                  </PStyle>
                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                    }}
                  >
                    Pris: 99 kr
                  </H6Style>
                </Grid>
                <Grid>
                  <H6Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                  >
                    Korv a la Forsell
                  </H6Style>
                  <PStyle
                    sx={{
                      my: '0px',
                    }}
                  >
                    Food meny description serveras med ris
                  </PStyle>
                  <H6Style
                    sx={{
                      // fontWeight: "bold",
                      color: '#3E3B4E',
                    }}
                  >
                    Pris: 99 kr
                  </H6Style>
                </Grid> */}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ));
      })}
      {/* <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <H5Style>Tuesday</H5Style>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <H5Style>Wednesday</H5Style>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <H5Style>Thursday</H5Style>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 'panel5'}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <H5Style>Friday</H5Style>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'space-between',
            }}
          >
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
            <Grid>
              <H6Style
                sx={{
                  color: '#3E3B4E',
                }}
              >
                Korv a la Forsell
              </H6Style>
              <PStyle
                sx={{
                  my: '0px',
                }}
              >
                Food meny description serveras med ris
              </PStyle>
              <H6Style
                sx={{
                  // fontWeight: "bold",
                  color: '#3E3B4E',
                }}
              >
                Pris: 99 kr
              </H6Style>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );
}
