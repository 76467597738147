import { useState } from 'react';
import React from 'react';
import {
  useLocation,
  Link as SideBarLink,
  useNavigate,
} from 'react-router-dom';
import './index.css';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import UpnizeLogo from '../../assets/images/Upnize-logo.png';
import { colors, Link, Menu, MenuItem } from '@mui/material';
import { pages } from '../../utils/pages';
import { useContext } from 'react';
import { Context } from '../../context/Context';
import CustomButton from '../CustomButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import classes from './System.module.css';
import CustomTypography from '../CustomTypography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useRef } from 'react';
import CookiePopup from '../CookiePopup';
import { goUpThePage } from '../../utils/goUpThePage';
import Collapse from '@mui/material/Collapse';
import H5Style from '../textComponents/H5Style';
import PStyle from '../textComponents/PStyle';

const drawerWidth = 240;

const resources = [
  {
    label: 'BLOG',
    text: 'Explore the latest on productivity, product news, tips, and more',
    link: '/blog',
  },
  {
    label: 'CONTACT SUPPORT',
    text: 'Any question can be answered',
    link: '/contact-support',
  },
  {
    label: 'HELP CENTER',
    text: 'Tutorials and how to guides for using Upnize appointment system',
    link: '/help-center',
  },
];

const System = ({ children }) => {
  const [openMenuRes, setOpenMenuRes] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const nav = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorLinkMenuEl, setAnchorLinkMenuEl] = useState(null);
  const [panelSwitch, setPanelSwitch] = useState(true);
  const open = Boolean(anchorEl);
  const linkMenuOpen = Boolean(anchorLinkMenuEl);
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  // const [drawer, setDrawer] = useState(null);

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const context = useContext(Context);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickMenu = (e) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(nav.current);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePanelSwitch = () => {
    setPanelSwitch(!panelSwitch);
    context.setUser({ role: 'CUSTOMER' });
  };

  // handle close of link menu
  const handleLinkMenuClose = () => {
    setAnchorLinkMenuEl(null);
  };

  //
  const handleLinkMenuClick = (event) => {
    setAnchorLinkMenuEl(event.currentTarget);
  };

  const handleNavigateSubLinkMenu = (link) => () => {
    navigate(link);
    handleLinkMenuClose();
    handleCloseMenu();
    goUpThePage();
  };

  const handleNavigation = (link) => () => {
    handleCloseMenu();
    navigate(link);
    goUpThePage();
  };

  return (
    <>
      <CookiePopup />
      <Menu
        anchorEl={anchorLinkMenuEl}
        open={linkMenuOpen}
        onClose={handleLinkMenuClose}
        onClick={handleLinkMenuClose}
        sx={{ marginTop: '10px' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Box sx={{ py: '10px' }}>
          {resources.map((resource) => (
            <MenuItem
              onClick={handleNavigateSubLinkMenu(resource.link)}
              key={resource.link}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '350px',
                my: '10px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <Typography sx={{ color: '#768B99', mb: '5px' }}>
                  {resource.label}
                </Typography>

                <Typography
                  sx={{
                    color: '#494949',
                    display: 'inline-block',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {resource.text}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Menu>

      <Menu
        anchorEl={anchorEl}
        id="navbar-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        transitionDuration={0}
        sx={{
          zIndex: 99,
          width: '100%',
          maxWidth: '100%',
        }}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '100%',
          },
        }}
        MenuListProps={{
          sx: {
            // width: anchorEl && anchorEl.offsetWidth,
            // border: "1px solid #e3eaf3",
            borderRadius: '0px',
            width: '100%',
            maxWidth: '100%',
            position: 'fixed',
            backgroundColor: 'white',
            right: 0,
            top: 95,
            zIndex: 1,
            boxShadow:
              '-6px 0 white, 6px 0 white,0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
          },
        }}
      >
        <MenuItem onClick={handleNavigation('/features')}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', ml: 2 }}>
            <Typography className={classes.header__title__typography}>
              FEATURES
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box
            id="resources-link"
            // onClick={handleLinkMenuClick}
            onClick={() => setOpenMenuRes(!openMenuRes)}
            sx={{ display: 'flex', alignItems: 'flex-end', ml: 2 }}
          >
            <Typography className={classes.header__title__typography}>
              RESOURCES
            </Typography>
            <Box sx={{ height: '20px' }}>
              {/* {anchorLinkMenuEl?.id === "resources-link" ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )} */}
              {openMenuRes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </Box>
        </MenuItem>
        <Collapse orientation="vertical" in={openMenuRes}>
          <Box sx={{ pb: '10px' }}>
            {resources.map((resource) => (
              <MenuItem
                onClick={handleNavigateSubLinkMenu(resource.link)}
                key={resource.link}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '350px',
                  // my: "10px",
                  ml: 2,
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography sx={{ color: '#768B99', mb: '5px' }}>
                    {resource.label}
                  </Typography>

                  <Typography
                    sx={{
                      color: '#494949',
                      display: 'inline-block',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {resource.text}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Box>
        </Collapse>

        <MenuItem
          onClick={() => {
            window.open('https://app.upnize.com/login');
            handleCloseMenu();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              ml: 2,
            }}
          >
            <Typography
              className={classes.header__title__typography}
              color={'#4A63F0 !important'}
              mr={'30px'}
            >
              SIGN IN
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('https://app.upnize.com/register');
            handleCloseMenu();
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              ml: 2,
            }}
          >
            <CustomButton>
              <Typography fontWeight={700} fontSize={16}>
                Get started
              </Typography>
            </CustomButton>
          </Box>
        </MenuItem>
      </Menu>

      <Box
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
        }}
        // aria-label="mailbox folders"
      >
        <AppBar
          position="fixed"
          ref={nav}
          sx={{
            width: { md: `100%` },
            pr: { xs: 2, sm: 5 },
            pl: { xs: 2, sm: 5 },
            height: '95px',
            backgroundColor: 'white',
            justifyContent: { xs: 'center', md: 'flex-start' },
            color: 'black',
            pb: 1.5,
            zIndex: 999,
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: "space-between",
                p: '0  !important',
                maxWidth: '1472px',
                m: 0,
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'self-end',
                  height: '36px',
                }}
              >
                <Box
                  onClick={handleNavigation('/')}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '140px',
                    // ml: { xs: 2, xl: 0 },
                  }}
                >
                  <img
                    src={UpnizeLogo}
                    alt=""
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    width: '100%',
                    alignItems: 'self-end',
                    height: '36px',
                  }}
                >
                  <Box
                    onClick={handleNavigation('/features')}
                    className={classes.header__title}
                  >
                    <Typography className={classes.header__title__typography}>
                      FEATURES
                    </Typography>
                  </Box>
                  <Box
                    id="resources-link"
                    onClick={handleLinkMenuClick}
                    className={classes.header__title}
                  >
                    <Typography className={classes.header__title__typography}>
                      RESOURCES
                    </Typography>
                    <Box sx={{ height: '20px' }}>
                      {anchorLinkMenuEl?.id === 'resources-link' ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: { md: 'space-between' },
                  width: '100%',
                }}
              >
                <Grid
                  container
                  xs={12}
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                  }}
                >
                  {/* <Box className={classes.header__title}>
                    <Typography
                      className={classes.header__title__typography}
                      color={"#000 !important"}
                      // mr={"30px"}
                      onClick={() => {
                        window.location.href = "/pricing";
                      }}
                    >
                      PRICING
                    </Typography>
                  </Box> */}
                  <Box className={classes.header__title}>
                    <Typography
                      className={classes.header__title__typography}
                      color={'#4A63F0 !important'}
                      mr={'30px'}
                      onClick={() => {
                        window.open('https://app.upnize.com/login');
                      }}
                    >
                      SIGN IN
                    </Typography>
                  </Box>

                  <CustomButton
                    sx={{ position: 'relative', top: 8.5 }}
                    onClick={() => {
                      window.open('https://app.upnize.com/register');
                    }}
                  >
                    <Typography fontWeight={700} fontSize={16}>
                      Get started
                    </Typography>
                  </CustomButton>
                </Grid>
              </Box>
              {/* burger */}
              <Box
                sx={{
                  display: { xs: 'inline', md: 'none' },
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Grid
                  container
                  xs={12}
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    mt: 1,
                    p: 0,
                  }}
                >
                  <IconButton onClick={handleClickMenu}>
                    <MenuIcon sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Grid>
              </Box>
            </Toolbar>
          </Box>
        </AppBar>

        <Grid
          container
          sx={{
            minWidth: {
              xs: '100%',
              md: `100%`,
            },
            mt: '95px',
            backgroundColor: '#F5F7FA',
          }}
        >
          <div
            id="system"
            style={{
              backgroundColor: 'white',
              width: '100%',
              overflowY: 'hidden',
              minHeight: '90vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ flex: 1 }}>{children}</Box>
            {/* footer */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ justifyContent: 'center' }}
                className="footer__container"
              >
                <Divider
                  sx={{
                    width: '100%',
                    mt: 5,
                    mb: 8,
                    border: '1px #E5E5FD solid',
                  }}
                />
                {/* first row */}

                <Grid
                  xs={12}
                  md={3}
                  sx={{
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: { xs: 4, xl: 0 },
                    }}
                  >
                    <Grid>
                      <img
                        src={UpnizeLogo}
                        style={{
                          width: '100px',
                          height: 'auto',
                          marginBottom: '20px',
                          marginTop: '5px',
                        }}
                        alt="logo"
                      />
                    </Grid>
                    <PStyle
                      style={{
                        marginTop: 0,
                        paddingRight: '30px',
                        // fontSize: "15px",
                        cursor: 'auto',
                      }}
                      className={classes.footer__vertical__spacing}
                    >
                      Upnize appointment system, takes care of your bookings
                      from bookings to revenue generation. Our goal is to build
                      fully automated solution for a diverse range of businesses
                      that rely on scheduling and booking appointments to handle
                      their own bookings, encounters, payments, notifications,
                      and website sales just in one place.
                    </PStyle>
                  </Box>
                </Grid>
                <Grid
                  xs={12}
                  md={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: { xs: 4, md: 0 },
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <H5Style
                    // fontWeight={"bold"}
                    sx={{
                      color: '#3E3B4E',
                    }}
                    className={classes.footer__headers}
                  >
                    Features
                  </H5Style>
                  <PStyle
                    onClick={handleNavigation('/features')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Integrations
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/features')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Automations
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/features')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Calendar
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/features')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Appointment
                  </PStyle>
                </Grid>
                <Grid
                  xs={12}
                  md={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: { xs: 4, md: 0 },
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <H5Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                    // fontWeight={"bold"}
                    className={classes.footer__headers}
                  >
                    Upnize products
                  </H5Style>
                  <Box display={'flex'}>
                    <PStyle
                      style={{ cursor: 'pointer' }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: '10px',
                        mt: '0px',
                      }}
                    >
                      Upnize Service
                    </PStyle>
                  </Box>
                  <Box display={'flex'}>
                    <PStyle
                      style={{ cursor: 'pointer' }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: '10px',
                        mt: '0px',
                      }}
                    >
                      Upnize Restaurant
                    </PStyle>
                  </Box>
                  <Box display={'flex'}>
                    <PStyle
                      style={{ cursor: 'pointer' }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: '10px',
                        mt: '0px',
                      }}
                    >
                      Upnize Network
                    </PStyle>
                  </Box>
                  {/* <Box display={"flex"}>
                    <PStyle
                      style={{ cursor: "pointer" }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: "10px",
                        mt: "0px",
                      }}
                    >
                      Upnize marketing
                    </PStyle>
                    <CustomTypography
                      fontWeight={"bold"}
                      color={"#5069E8"}
                      ml={1}
                      mt={0.3}
                    >
                      Coming soon
                    </CustomTypography>
                  </Box>
                  <Box display={"flex"}>
                    <PStyle
                      style={{ cursor: "pointer" }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: "10px",
                        mt: "0px",
                      }}
                    >
                      Upnize Product sales
                    </PStyle>
                    <CustomTypography
                      fontWeight={"bold"}
                      color={"#5069E8"}
                      ml={1}
                      mt={0.3}
                    >
                      Coming soon
                    </CustomTypography>
                  </Box>
                  <Box display={"flex"}>
                    <PStyle
                      style={{ cursor: "pointer" }}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: "10px",
                        mt: "0px",
                      }}
                    >
                      Upnize partner network
                    </PStyle>
                    <CustomTypography
                      fontWeight={"bold"}
                      color={"#5069E8"}
                      ml={1}
                      mt={0.3}
                    >
                      Coming soon
                    </CustomTypography>
                  </Box> */}
                </Grid>
                <Grid
                  xs={12}
                  md={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: { xs: 4, md: 0 },
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <H5Style
                    // fontWeight={"bold"}
                    sx={{
                      color: '#3E3B4E',
                    }}
                    className={classes.footer__headers}
                  >
                    Company
                  </H5Style>
                  <Box display={'flex'}>
                    <PStyle
                      onClick={handleNavigation('/network')}
                      className={classes.footer__vertical__spacing}
                      variant="p"
                      sx={{
                        mb: '10px',
                        mt: '0px',
                      }}
                    >
                      Network
                    </PStyle>
                  </Box>
                  <PStyle
                    onClick={handleNavigation('/subscribe')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Subscribe to newsletter
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/news')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    News
                  </PStyle>

                  <PStyle
                    onClick={handleNavigation('/terms-of-service')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Terms of service
                  </PStyle>
                  {/* <PStyle
                    onClick={handleNavigation("/pricing")}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: "10px",
                      mt: "0px",
                    }}
                  >
                    Price
                  </PStyle> */}
                </Grid>
                <Grid
                  xs={12}
                  md={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: { xs: 4, md: 0 },
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <H5Style
                    sx={{
                      color: '#3E3B4E',
                    }}
                    // fontWeight={"bold"}
                    className={classes.footer__headers}
                  >
                    Resources
                  </H5Style>
                  <PStyle
                    onClick={handleNavigation('/help-center')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Help center
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/blog')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Blog
                  </PStyle>
                  <PStyle
                    onClick={handleNavigation('/contact-support')}
                    className={classes.footer__vertical__spacing}
                    variant="p"
                    sx={{
                      mb: '10px',
                      mt: '0px',
                    }}
                  >
                    Contact Support
                  </PStyle>
                </Grid>

                {/* second row */}
                <Grid
                  xs={12}
                  sx={{
                    mt: 10,
                    mb: { xs: 2, md: 4 },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', md: 'start' },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          onClick={handleNavigation('/')}
                          variant="p"
                          sx={{
                            paddingRight: '5px',
                            color: '#7b8b98',
                            textDecoration: 'none',
                            marginLeft: '20px',
                            cursor: 'pointer',
                            '&:hover': {
                              color: colors.blue[900],
                            },
                          }}
                        >
                          © 2022 Upnize
                        </Box>
                        <p style={{ color: '#7b8b98' }}>|</p>
                        <Box
                          onClick={handleNavigation('/contact')}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              color: colors.blue[900],
                            },
                          }}
                          variant="a"
                          target="_blank"
                          className={classes.copyrightLink}
                        >
                          Need help?
                        </Box>
                        <p style={{ color: '#7b8b98' }}>|</p>
                        <Box
                          onClick={handleNavigation('/terms-of-service')}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              color: colors.blue[900],
                            },
                          }}
                          variant="a"
                          target="_blank"
                          className={classes.copyrightLink}
                        >
                          Terms of service
                        </Box>
                      </Box>
                      <Box
                        ml={6}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <LinkedInIcon sx={{ color: '#545767' }} />
                        <FacebookIcon sx={{ color: '#545767', ml: 1 }} />
                        <YouTubeIcon sx={{ color: '#545767', ml: 1 }} />
                        <TwitterIcon sx={{ color: '#545767', ml: 1 }} />
                        <InstagramIcon sx={{ color: '#545767', ml: 1 }} />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </div>
            </Box>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default System;
