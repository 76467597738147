import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import "./assets/styles/App.css";
import { ContextProvider } from "./context/Context";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { HelmetProvider, Helmet } from "react-helmet-async";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Arial",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "-apple-system",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  return (
    <HelmetProvider>
      <Helmet>
        <title>UPNIZE - Booking appointments Free forever</title>
        <meta name="description" content="UPNIZE booking appointment is built for multiple industries to handle bookings, encounters, payments, notifications, and website sales in a single place."></meta>
        <meta name="keywords" content="Upnize, Booking, Appointment, Restaurant, Beauty Salon, Payment, Notifications, Sales"></meta>
      </Helmet>
    <BrowserRouter>
      {/* <InfoProvider> */}
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </ContextProvider>
      {/* </InfoProvider> */}
    </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
