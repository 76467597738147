import {
  Box,
  Button,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded"; // CSV
import MailOutlinedIcon from "@mui/icons-material/MailOutlined"; // send test email
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"; // remove
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation"; // invite provider
import { Modal as MUIModal } from "@mui/material";
import { useRef } from "react";

const ShareModal = ({ handleOpenModal, type, open, service }) => {
  const uploadedFile = useRef();
  const [bookMarkData, setBookMarkData] = useState({
    service: "select",
    location: "select",
    staff: "select",
  });
  const handelChange = (e) => {
    const { name, value } = e.target;
    setBookMarkData({ ...bookMarkData, [name]: value });
  };

  const handleUpload = () => {
    uploadedFile.current.click();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("uploaded");
  };

  return (
    <MUIModal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { md: 550, xs: "100%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          outline: "none",
          borderRadius: "20px",
        }}
      >
        {/* HEADER */}
        <Box
          borderBottom="1px solid #b7b7b7"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "16px 20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {type != "SERVICE" && (
              <Box
                sx={{
                  backgroundColor: type === "DELETE" ? "#EE463C" : "#0072D9",
                  p: 3,
                  borderRadius: "15px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {type === "DELETE" && (
                  <DeleteOutlinedIcon sx={{ color: "#ffffff" }} />
                )}
                {type === "SEND_TEST_EMAIL" && (
                  <MailOutlinedIcon sx={{ color: "#ffffff" }} />
                )}
                {type === "UPLOAD" && (
                  <FileUploadRoundedIcon sx={{ color: "#ffffff" }} />
                )}
                {type === "INVITE" && (
                  <InsertInvitationIcon sx={{ color: "#ffffff" }} />
                )}
                {type === "SHARE" && (
                  <InsertInvitationIcon sx={{ color: "#ffffff" }} />
                )}
              </Box>
            )}
            <Typography
              variant="h4"
              fontSize="20px"
              fontWeight="bold"
              ml={1.5}
              sx={{
                p: 0.5,
              }}
            >
              {type === "DELETE" && "Remove"}
              {type === "SEND_TEST_EMAIL" && "Send a test email"}
              {type === "UPLOAD" && "Upload"}
              {type === "INVITE" && "Invite provider"}
              {type === "SHARE" && "Share booking widget"}
              {type === "SERVICE" && service?.name}
            </Typography>
          </Box>
          <Icon sx={{ cursor: "pointer" }} onClick={handleOpenModal}>
            <CloseIcon />
          </Icon>
        </Box>

        {/* body */}

        <Grid
          sx={{
            p: "0px 20px 10px 20px",
          }}
        >
          {type === "DELETE" && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>Are you sure you want to remove?</Typography>
              </Box>
              <Grid container sx={{ justifyContent: "end" }}>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    backgroundColor: "white",
                    color: "#b3a3a6",
                    height: "45px",
                    border: "1px solid #b3a3a6",
                    ":hover": {
                      backgroundColor: "white",
                      color: "#b3a3a6",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  No
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: "45px",
                  }}
                  variant="contained"
                  color="error"
                >
                  yes
                </Button>
              </Grid>
            </>
          )}
          {type === "SERVICE" && (
            <>
              <Box
                sx={{
                  width: "100%",
                  my: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{service?.body}</Typography>
              </Box>
            </>
          )}
          {type === "SHARE" && (
            <>
              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Service</Typography>
                  <Select
                    sx={{ width: "100%", mt: "5px" }}
                    size="small"
                    name="service"
                    value={bookMarkData.service}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: "none" }}>
                      Select
                    </MenuItem>
                    <MenuItem value="fatTansfar">Fat Tanasfar</MenuItem>
                    <MenuItem value="3dmotion">3D Motionn</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={6} item marginTop="1.5rem">
                  <Typography>Location</Typography>
                  <Select
                    sx={{ width: "100%", mt: "5px" }}
                    size="small"
                    name="location"
                    value={bookMarkData.location}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: "none" }}>
                      Select
                    </MenuItem>
                    <MenuItem value="dhaka">Dhaka</MenuItem>
                    <MenuItem value="india">India</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom="2rem">
                <Grid xs={6} item>
                  <Typography>Staff</Typography>
                  <Select
                    sx={{ width: "100%", mt: "5px" }}
                    size="small"
                    name="staff"
                    value={bookMarkData.staff}
                    onChange={handelChange}
                  >
                    <MenuItem value="select" sx={{ display: "none" }}>
                      Select
                    </MenuItem>
                    <MenuItem value="Alex">AlexGender</MenuItem>
                    <MenuItem value="Hasibul">Hasibul</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid marginBottom="1.5rem">
                <Box
                  item
                  sx={{
                    background: "#d8d8d8",
                    padding: "13px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    https://demo.booknetic.com/?page_id=2&service=43
                  </Typography>
                </Box>
              </Grid>
              <Grid container sx={{ justifyContent: "end" }}>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    backgroundColor: "white",
                    color: "#b3a3a6",
                    height: "45px",
                    border: "1px solid #b3a3a6",
                    ":hover": {
                      backgroundColor: "white",
                      color: "#b3a3a6",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: "45px",
                  }}
                  variant="contained"
                >
                  Share
                </Button>
              </Grid>
            </>
          )}
          {type === "INVITE" && (
            <>
              <Grid marginBottom="1.5rem">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ mt: 3, mb: 1, color: "#515151" }}>
                    Message
                  </Typography>
                  <TextField
                    multiline
                    maxRows={2}
                    rows={2}
                    placeholder="Your message here..."
                  ></TextField>
                  <Typography sx={{ mt: 3, mb: 1, color: "#515151" }}>
                    Provider email
                  </Typography>
                  <TextField
                    multiline
                    placeholder="example@email.com"
                  ></TextField>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: "end" }}>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    backgroundColor: "white",
                    color: "#b3a3a6",
                    height: "45px",
                    border: "1px solid #b3a3a6",
                    ":hover": {
                      backgroundColor: "white",
                      color: "#b3a3a6",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: "45px",
                  }}
                  variant="contained"
                >
                  Invite
                </Button>
              </Grid>
            </>
          )}
          {type === "SEND_TEST_EMAIL" && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: "200px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid item xs={12} mb={2}>
                    <Typography variant="subtitle2">Email</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // value={formData.languageName}
                      // onChange={(e) => handleFormData("email", e.target.value)}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      placeholder="Example@test.com"
                      sx={{
                        marginBottom: "2rem",
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: "end" }}>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    backgroundColor: "white",
                    color: "#b3a3a6",
                    height: "45px",
                    border: "1px solid #b3a3a6",
                    ":hover": {
                      backgroundColor: "white",
                      color: "#b3a3a6",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: "45px",
                  }}
                  variant="contained"
                >
                  SEND
                </Button>
              </Grid>
            </>
          )}
          {type === "UPLOAD" && (
            <>
              <Grid
                marginBottom="1.5rem"
                marginTop="1.5rem"
                sx={{ height: "200px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      border: "#D6D6D6 dashed 2px",
                      cursor: "pointer",
                    }}
                    onClick={handleUpload}
                    onDrop={(e) => handleDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                  >
                    <Box>
                      <Typography color={"#D6D6D6"}>
                        Drop your CSV file here or click here to upload
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        required
                        sx={{
                          marginBottom: "1rem",
                          width: "100%",
                          fontSize: "14px",
                          display: "none",
                        }}
                        // onChange={(e) => getUserImage(e.target.files[0])}
                        inputRef={uploadedFile}
                        type="file"
                      />
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid container sx={{ justifyContent: "end" }}>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    backgroundColor: "white",
                    color: "#b3a3a6",
                    height: "45px",
                    border: "1px solid #b3a3a6",
                    ":hover": {
                      backgroundColor: "white",
                      color: "#b3a3a6",
                    },
                  }}
                  variant="contained"
                  onClick={handleOpenModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: { xs: "100%", sm: 130 },
                    marginBottom: 1,
                    marginLeft: { xs: 0, sm: 2 },
                    height: "45px",
                  }}
                  variant="contained"
                >
                  Upload
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </MUIModal>
  );
};

export default ShareModal;
