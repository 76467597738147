import { serviceProvider } from './serviceProvider';

export const getBlogsAPI = (data) =>
  serviceProvider.get(`/articles?type=blog`, data);

export const getCategoriesAPI = (data) =>
  serviceProvider.get(`/categories`, data);

export const getPlansAPI = (data) => serviceProvider.get(`/pricePlans`, data);

export const getNewsListAPI = (data) =>
  serviceProvider.get(`/articles?type=news`, data);

export const getNewsListFilterAPI = (data) =>
  serviceProvider.get(
    `/articles?type=news&categoryId=${data.categoryId}&keyword=${data.keyword}`
  );

export const getArticleByIdAPI = (articleId) =>
  serviceProvider.get(`/articles/byId/${articleId}`);

export const getArticleByLinkAPI = (articleId) =>
  serviceProvider.get(`/articles/byId/${articleId}`);

export const getDocsAPI = (data) =>
  serviceProvider.get(`/articles?type=help`, data);

export const addSubscriberAPI = (data) =>
  serviceProvider.post('/subscriber/add', data);

export const getFiltersDataAPI = (filterType, countryCode = null) =>
  serviceProvider.get(`/list/${filterType}`);

export const contactSupportAPI = (data) =>
  serviceProvider.post('/contactUs', data);

// LocationId
export const getBooking = (queries, TenantId) =>
  serviceProvider.get(`/booking/full?${queries ? queries : ''}`, {
    headers: {
      TenantId,
    },
  });

export const getCompDetails = (loctionId) =>
  serviceProvider.get(`companyProfile/${loctionId}`);

export const getLocations = (keyword, location, industry) =>
  serviceProvider.get(
    `/locations?keyword=${keyword}&location=${location}&industry=${industry}&rows=9999`
  );
