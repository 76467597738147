const StepData = () => [
  {
    id: 1,
    title: 'Select Location',
    type: 'location',
    options: [
      'Götgatan 45, Göteborg, Sweden',
      'Götgatan 46, Göteborg, Sweden',
      'Götgatan 47, Göteborg, Sweden',
      'Götgatan 48, Göteborg, Sweden',
    ],
    stepActive: '/img/stepIcon/Locationactive.png',
    stepDeActive: '/img/stepIcon/Location-unactive.png',
  },
  {
    id: 2,
    title: 'Select Staff',
    type: 'staff',
    options: [
      { name: 'Any staff member', img: '/img/Any-staff.png' },
      {
        name: 'Mariella Oskarsson',
        img: '../../assets/img/user1.jpeg',
        email: 'mariella800727@hotmail.com',
        number: '070797979797',
      },
      {
        name: 'Alexander Inci',
        img: '/img/user1.jpeg',
        email: 'incialexander@gmail.com',
        number: '+46 7079797877',
      },
      {
        name: 'Stephano istroo',
        img: '/img/user1.jpeg',
        email: 'Stephan.istroo@gmail.com',
        number: '070797979797',
      },
    ],
    stepActive: '/img/stepIcon/Staff-active.png',
    stepDeActive: '/img/stepIcon/Staff-unactive.png',
  },
  {
    id: 3,
    title: 'Select Service',
    type: 'service',
    options: [
      {
        id: 1,
        name: 'category 1',
        subOption: [
          { serviceName: 'This is Service1', time: '1h 20min', price: 200 },
          { serviceName: 'This is Service2', time: '1h 30min', price: 300 },
          { serviceName: 'This is Service3', time: '1h 40min', price: 400 },
        ],
        collapse: false,
      },
      {
        id: 2,
        name: 'category 2',
        subOption: [
          { serviceName: 'This is Service1', time: '1h 20min', price: 200 },
        ],
        collapse: false,
      },
      {
        id: 3,
        name: 'category 3',
        subOption: [
          { serviceName: 'This is Service1', time: '1h 20min', price: 200 },
          { serviceName: 'This is Service2', time: '1h 30min', price: 300 },
          { serviceName: 'This is Service3', time: '1h 40min', price: 400 },
          { serviceName: 'This is Service4', time: '1h 50min', price: 500 },
          { serviceName: 'This is Service5', time: '1h 60min', price: 600 },
        ],
        collapse: false,
      },
      {
        id: 4,
        subOption: {
          serviceName: 'This is Service1',
          time: '1h 20min',
          price: 200,
        },
      },
    ],
    stepActive: '/img/stepIcon/Service-active.png',
    stepDeActive: '/img/stepIcon/Service-unactive.png',
  },
  // {
  //   id: 9,
  //   title: "Select Date & Time",
  //   type: "dailytime",

  //   stepActive: "/img/stepIcon/Date-active.png",
  //   stepDeActive: "/img/stepIcon/Date-unactive.png",
  // },
  {
    id: 4,
    title: 'Select Service Extra',
    type: 'extraService',
    options: [
      {
        serviceName: 'Service extra title name1',
        time: '1h 20min',
        price: 200,
      },
      { serviceName: 'Service extra title name2' },
      {
        serviceName: 'Service extra title name3',
        time: '1h 40min',
        price: 400,
      },
      {
        serviceName: 'Service extra title name4',
        time: '1h 50min',
        price: 500,
      },
    ],
    stepActive: '/img/stepIcon/Service-extra-active.png',
    stepDeActive: '/img/stepIcon/Service-extra-unactive.png',
  },
  {
    id: 5,
    title: 'Select Date & Time',
    type: 'datetime',
    options: [
      // {
      //   id: "1",
      //   time: "8.00am - 9.00am",
      // },
      // {
      //   id: "2",
      //   time: "9.00am - 10.00am",
      // },
      // {
      //   id: "3",
      //   time: "10.00am - 11.00am",
      // },
      // {
      //   id: "4",
      //   time: "11.00am - 12.00pm",
      // },
      // {
      //   id: "5",
      //   time: "12.00pm - 1.00pm",
      // },
      // {
      //   id: "6",
      //   time: "1.00pm - 2.00pm",
      // },
      // {
      //   id: "7",
      //   time: "2.00pm - 2.00pm",
      // },
      // {
      //   id: "8",
      //   time: "2.00pm - 3.00pm",
      // },
    ],
    stepActive: '/img/stepIcon/Date-active.png',
    stepDeActive: '/img/stepIcon/Date-unactive.png',
  },
  {
    id: 6,
    title: 'Select number of guests',
    type: 'people',
    options: Array.from({ length: 30 }, (_, i) => i + 1),
    stepActive: '/img/stepIcon/Number-of-people-active.png',
    stepDeActive: '/img/stepIcon/Number-people-unactive.png',
  },
  {
    id: 7,
    title: 'Enter Information',
    type: 'auth',
    isLogin: false,
    stepActive: '/img/stepIcon/Enter-information-active.png',
    stepDeActive: '/img/stepIcon/Enter-information-unactive.png',
  },
  {
    id: 8,
    type: 'allInfo',
    isOpen: 'Summary',
    stepActive: '/img/stepIcon/Confirmation-active.png',
    stepDeActive: '/img/stepIcon/Confirmation-unactive.png',
  },
  {
    id: 9,
    type: 'payment',
    isOpen: 'Payment',
    stepActive: '/img/stepIcon/Payment-active.png',
    stepDeActive: '/img/stepIcon/Payment-unactive.png',
  },
];
export default StepData;
