import classes from "./Highlighted.module.css"

const Highlighted = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark key={i} className={classes.markedText}>{part}</mark>
          ) : (
            <span key={i} className={classes.textElement}>
              {part}
            </span>
          )
        )}
    </span>
  );
};

export default Highlighted;
