import { Box, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import useGetInfo from "./hook/useGetInfo";

const ServiceOption = ({
  options,
  handelClick,
  stepId,
  isChecked,
  infoList,
  // handelCollapes,
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading } = useGetInfo();

  const [optionsData, setoptionsData] = useState(options);

  const handelCollaps = (optionId) => {
    const data = optionsData.map((sub) =>
      sub.id === optionId ? { ...sub, collapse: !sub.collapse } : sub
    );
    setoptionsData(data);
  };
  const checkAnsCollaps = () => {
    const subOption = infoList.find((option) => option.id === stepId);
    if (subOption) {
      const data = optionsData.map((sub) =>
        sub.id === subOption.subId ? { ...sub, collapse: true } : sub
      );
      setoptionsData(data);
    }
  };

  useEffect(() => {
    // checkAnsCollaps();
    // console.log("optionsData", optionsData);
    console.log("***********");
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {optionsData.map((option, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {!option.name ? (
                // <Box sx={{ width: "100%" }}>
                //   <Box
                //     sx={{
                //       display: "flex",
                //       alignItems: "center",
                //       justifyContent: "space-between",
                //       height: " 60px",
                //       border: "1px solid #ddd",
                //       margin: "10px",
                //       padding: "0px 20px",
                //     }}
                //   >
                //     <Box sx={{ display: "flex", py: "4px" }}>
                //       <Typography variant="p" fontSize="18px">
                //         {option.name}
                //       </Typography>
                //     </Box>
                //     <Box sx={{ display: "flex", alignItems: "center" }}>
                //       <Typography
                //         variant="p"
                //         fontSize="14px"
                //         color="#939AB5"
                //         paddingRight="5px"
                //       >
                //         {option.subOption.length} services
                //       </Typography>
                //       <Box
                //         sx={{
                //           borderLeft: "1px solid #ddd",
                //           paddingLeft: "1rem",
                //         }}
                //       >
                //         {option.collapse ? (
                //           <RemoveIcon
                //             style={{
                //               color: "#4B64E9",
                //               border: "1px solid #4B64E9",
                //               borderRadius: "5px",
                //             }}
                //             onClick={() => handelCollaps(option.id)}
                //           />
                //         ) : (
                //           <AddIcon
                //             style={{
                //               color: "#4B64E9",
                //               border: "1px solid #4B64E9",
                //               borderRadius: "5px",
                //             }}
                //             onClick={() => handelCollaps(option.id)}
                //           />
                //         )}
                //       </Box>
                //     </Box>
                //   </Box>
                //   {option.collapse && (
                //     <>
                //       {" "}
                //       {option.subOption.map((sub, index) => (
                //         <Box
                //           sx={{
                //             display: "flex",
                //             alignItems: "center",
                //             justifyContent: "space-between",
                //             border: "1px solid #ddd",
                //             margin: "10px",
                //             padding: "4px 20px",
                //             height: "60px",
                //             cursor: "pointer",
                //           }}
                //           key={index}
                //           onClick={() =>
                //             handelClick(
                //               sub.serviceName,
                //               stepId,
                //               "service",
                //               option.id,
                //               sub.price
                //             )
                //           }
                //         >
                //           <Box
                //             sx={{
                //               display: "flex",
                //               flexDirection: "column",
                //               py: "4px",
                //             }}
                //           >
                //             <Typography variant="p" fontSize="18px">
                //               {sub.serviceName}
                //             </Typography>
                //             <Typography
                //               variant="p"
                //               fontSize="14px"
                //               color="#939AB5"
                //               sx={{
                //                 display: "flex",
                //                 width: { xs: "80%", md: "300px" },
                //                 justifyContent: "space-between",
                //               }}
                //               whiteSpace="nowrap"
                //             >
                //               <span
                //                 style={{
                //                   display: "flex",
                //                   alignItems: "center",
                //                 }}
                //               >
                //                 Note
                //                 <InfoIcon
                //                   style={{
                //                     fontSize: "18px",
                //                     marginLeft: "5px",
                //                     cursor: "pointer",
                //                     marginRight: "10px",
                //                   }}
                //                 />
                //               </span>
                //               {sub.time}
                //             </Typography>
                //           </Box>
                //           <Box
                //             sx={{
                //               display: "flex",
                //               alignItems: "center",
                //               justifyContent: "space-between",
                //             }}
                //           >
                //             <Typography
                //               variant="h6"
                //               fontWeight="bold"
                //               color="primary"
                //               paddingRight="5px"
                //             >
                //               ${sub.price}
                //             </Typography>
                //             <Box sx={{ borderLeft: "1px solid #ddd" }}>
                //               <Checkbox
                //                 sx={{ padding: "0px", marginLeft: "1rem" }}
                //                 checked={isChecked(
                //                   sub.serviceName,
                //                   stepId,
                //                   option.id
                //                 )}
                //                 classes={{ root: "checkbox" }}
                //               />
                //             </Box>
                //           </Box>
                //         </Box>
                //       ))}
                //     </>
                //   )}
                // </Box>
                <></>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    border: "1px solid #ddd",
                    margin: "10px",
                    padding: "4px 20px",
                    height: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handelClick(
                      // option.subOption.serviceName,
                      option,
                      stepId,
                      "service",
                      // option.id,
                      option.id,
                      // option.subOption.price
                      option.price
                    )
                  }
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", py: "4px" }}
                  >
                    <Typography variant="p" fontSize="18px">
                      {/* {option.subOption.serviceName} */}
                      {option.name}
                    </Typography>
                    <Typography
                      variant="p"
                      fontSize="14px"
                      color="#939AB5"
                      sx={{
                        display: "flex",
                        // width: { xs: "80%", md: "300px" },
                        justifyContent: "space-between",
                      }}
                      whiteSpace="nowrap"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Note
                        <InfoIcon
                          style={{
                            fontSize: "18px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                        />
                      </span>
                      {/* {option.subOption.time} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="primary"
                      paddingRight="5px"
                    >
                      {/* ${option.subOption.price} */}
                      {option.price}
                    </Typography>
                    <Box sx={{ borderLeft: "1px solid #ddd" }}>
                      <Checkbox
                        sx={{ padding: "0px", marginLeft: "1rem" }}
                        checked={isChecked(
                          // option.subOption.serviceName,
                          option,
                          stepId,
                          // option.id
                          option.id
                        )}
                        classes={{ root: "checkbox" }}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default ServiceOption;
