import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import useGetInfo from "./hook/useGetInfo";

const GiftCard = ({ VAT }) => {
  const { setPriceInfo, priceInfo, total } = useGetInfo();
  const [cardNumber, setCardNumber] = useState("");
  const [showAmount, setShowAmount] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({});
  const [amount, setAmount] = useState();
  const [amountError, setAmountError] = useState("");
  const handelChnage = (e) => {
    setCardNumber(e.target.value);
    setError("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cardNumber.length === 0) {
      setError("Card Number is Required");
    }
    try {
      if (cardNumber === "123456789") {
        // factch POST for submit coupon
        // GET RESPONSE AND SET DATA
        const data = { id: 10, type: "GiftCard", balance: 500 };
        setData(data);
        setShowAmount(true);
      }
    } catch (error) {}
  };
  const handelAmount = (e) => {
    e.preventDefault();
    if (amount.length === 0) {
      setAmountError("Amount is Required");
      return;
    }
    if (!data.id) {
      setError("Please Add your card");
    }
    try {
      if (data.balance < amount) {
        setAmountError("Enter value is higher then the gift card");
      } else if (total < amount) {
        setAmountError(`You can't add value higher than ${total}`);
      } else {
        // const giftCardAmount =
        setData({
          ...data,
          balance: data.balance - amount,
          amount: parseInt(amount),
        });
        console.log(data);
        setPriceInfo([
          ...priceInfo,
          { giftCard: data.balance, amount: parseInt(amount) },
        ]);
      }
    } catch (error) {}
  };

  return (
    <Box marginY="15px">
      <form onSubmit={handleSubmit}>
        <TextField
          placeholder="123456789"
          onChange={handelChnage}
          value={cardNumber}
          sx={{ width: { md: "45%", xs: "100%" } }}
          error={!!error}
          helperText={error ? error : ""}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
        <Button
          variant="contained"
          sx={{
            height: "45px",
            width: "15%",
            marginLeft: { xs: "0", md: "18px" },
            marginTop: { xs: "20px", md: "0" },
            padding: { xs: "10px", md: "14px" },
          }}
          type="submit"
        >
          Okay
        </Button>
      </form>
      {showAmount && (
        <form onSubmit={handelAmount} style={{ marginTop: "2rem" }}>
          <Box marginY="15px">
            <Typography variant="p">
              The gift card has a value of ${data.balance}. Enter the value you
              like to use
            </Typography>
          </Box>
          <TextField
            type="number"
            placeholder="value"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            sx={{ width: { md: "45%", xs: "100%" } }}
            error={!!amountError}
            helperText={amountError ? amountError : ""}
            inputProps={{
              style: {
                height: "45px",
                padding: "0 10px",
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              height: "45px",
              width: "15%",
              marginLeft: { xs: "0", md: "18px" },
              marginTop: { xs: "20px", md: "0" },
              padding: { xs: "10px", md: "14px" },
            }}
            type="submit"
          >
            Okay
          </Button>
        </form>
      )}

      {data.amount && (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              GiftCard
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              ${data.amount}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
      >
        <Box marginY="7px">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Total Price
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${total}
          </Typography>
        </Box>
        <Box marginY="7px" textAlign="end">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Vat
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${VAT.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GiftCard;
