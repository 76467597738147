import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CustomTypography from "../../components/CustomTypography";
import recurringImage from "../../assets/images/subscribe/Recurring-Appointments-Upnize.svg";
import giftCard from "../../assets/images/subscribe/Gift-card-Upnize.svg";
import calendar from "../../assets/images/subscribe/Calendar-Upnize.svg";
import notifications from "../../assets/images/subscribe/Notification-Upnize.svg";
import gear from "../../assets/images/subscribe/Google-meet-integration-Upnize.svg";
import coupon from "../../assets/images/subscribe/Coupon-Upnize.svg";
import reminder from "../../assets/images/subscribe/Reminder-Upnize.svg";
import pin from "../../assets/images/subscribe/Multi-location-Upnize.svg";
import employee from "../../assets/images/subscribe/Employee-Upnize.svg";
import restaurant from "../../assets/images/subscribe/Restaurant-dinner-booking.jpg";
import users from "../../assets/images/subscribe/users.png";
import classes from "./Subscribe.module.css";
import { Divider } from "@mui/material";
import RegisterYourCompany from "../../components/RegisterYourCompany";
import RegisterToBeNotified from "../../components/RegisterToBeNotified";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { getFiltersDataAPI } from "../../services";
import H1Style from "../../components/textComponents/H1Style";
import H2Style from "../../components/textComponents/H2Style";
import H5Style from "../../components/textComponents/H5Style";
import PStyle from "../../components/textComponents/PStyle";

const Subscribe = () => {
  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);

  const getFiltersSelectionsFromAPI = useCallback(async () => {
    try {
      const countriesResponse = await getFiltersDataAPI("countries");
      const industriesResponse = await getFiltersDataAPI("industries");
      setCountriesList(countriesResponse.data);
      setIndustriesList(industriesResponse.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getFiltersSelectionsFromAPI();
  }, [getFiltersSelectionsFromAPI]);

  // calls the api to fetch all of the cities by countryCode
  const handleCitiesList = async (countryCode) => {
    try {
      const citiesResponse = await getFiltersDataAPI("cities", countryCode);
      setCitiesList(citiesResponse.data[countryCode]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      item
      container
      sx={{
        maxWidth: "1240px",
        marginX: "auto",
        justifyContent: "center",
        paddingLeft: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
        paddingRight: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      {/* first section */}
      <div className={classes.mainContainer}>
        <H1Style
          sx={{
            marginTop: 12,
            marginBottom: 5,
            mx: { xs: 2, xl: 0 },
          }}
          textAlign={"center"}
          variant="h1"
        >
          We are ready soon to lunch the perfect booking application with all
          industries and powerful features
        </H1Style>
        <H2Style
          sx={{
            marginBottom: 14,
            mx: { xs: 2, xl: 0 },
          }}
          textAlign={"center"}
          variant="h2"
        >
          Up organize your company with our booking appointment system
        </H2Style>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            mx: { xs: 2, xl: 0 },
          }}
        >
          <Grid
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              className={classes.heroSection__images}
              src={calendar}
              alt=""
            />

            <H5Style textAlign={"center"} mb={"5px"}>
              Calendar
            </H5Style>
            <PStyle textAlign={"center"} sx={{ mb: { xs: 5, md: 0 } }}>
              With an up-to-date interface and informative calendar, you can see
              your employer schedule. Whether you choose a monthly schedule, a
              weekly schedule or you can look at the daily schedule with
              check-in clients or booking appointment.
            </PStyle>
          </Grid>
          <Grid md={3 / 4}></Grid>
          <Grid
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              className={classes.heroSection__images}
              src={giftCard}
              alt=""
            />

            <H5Style textAlign={"center"} mb={"5px"}>
              Giftcards
            </H5Style>
            <PStyle textAlign={"center"} sx={{ mb: { xs: 5, md: 0 } }}>
              Our gift card features help the business create a certain mount
              for a specific gift card. The gift card can be used when making an
              appointment or check in then the money paid will be out of the
              gift card balance.
            </PStyle>
          </Grid>
          <Grid md={3 / 4}></Grid>

          <Grid
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              className={classes.heroSection__images}
              src={recurringImage}
              alt=""
            />
            <H5Style textAlign={"center"} mb={"5px"}>
              Recurring Appointments
            </H5Style>
            <PStyle textAlign={"center"}>
              With the Recurring Appointments feature, you can give your
              customers the power to choose their own appointment days. <br />
              This way, they never have to worry about being available on a
              certain day and time – they can pick what works best for them!
            </PStyle>
          </Grid>
        </Box>
      </div>

      {/* second section */}

      <Divider
        sx={{
          width: "100%",
          mt: "100px",
          mb: "100px",
          border: "1px #E5E5FD solid",
        }}
      />

      <div
        className="body__container"
        style={{
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Grid xs={12} md={6}>
            <Box>
              <H1Style
                sx={{
                  marginBottom: 4,
                }}
              >
                For companies
              </H1Style>
              <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                <Grid xs={12} md={6}>
                  <H5Style
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    Clients management
                  </H5Style>
                  <PStyle
                    sx={{
                      marginRight: 2,
                    }}
                  >
                    The Booking application keeps track of all of the client’s
                    information. Helping business owners manage clients
                    information in every time clients check-in or make a booking
                    without waiting time and effort.
                  </PStyle>
                </Grid>
                <Grid xs={12} md={6}>
                  <H5Style
                    sx={{
                      marginBottom: 2,
                      mt: { xs: 4, md: 0 },
                    }}
                  >
                    Online Booking 24/7
                  </H5Style>
                  <PStyle
                    sx={{
                      marginRight: 2,
                    }}
                  >
                    Our booking appointment feature is very flexible and can be
                    used by a wide variety of businesses.
                  </PStyle>
                </Grid>

                <img
                  src={restaurant}
                  alt=""
                  // className={classes.forCompanies__image}
                  style={{
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <RegisterYourCompany
            countriesList={countriesList}
            citiesList={citiesList}
            industriesList={industriesList}
            handleCitiesList={handleCitiesList}
          />
        </Box>
      </div>

      {/* third section */}
      <Divider
        sx={{
          width: "100%",
          border: "1px #E5E5FD solid",
          position: "relative",
          zIndex: 3,
          mt: "100px",
          mb: "100px",
        }}
      />
      {/* background triangle */}
      {/* <Box
        sx={{
          position: "relative",
          left: 0,
          right: 0,
          width: "100%",
          // zIndex: 1,
        }}
      >
        <Box
          sx={{
            // width: "100%",
            height: "100vw",
            zIndex: 1,
            // borderTop: "49.9vw solid transparent",
            // borderRight: "99vw solid rgb(249, 249, 252)",
            // borderBottom: "50vw solid transparent",
            position: "absolute",
            // top: "-50vw",
            right: "0px",
          }}
        >
          <svg
            viewBox="163.953 47.027 268.924 322.709"
            // width="268.924px"
            // height="322.709px"
            style={{
              height: "200vh",
              width: "100%",
            }}
          >
            <path
              d="M 459.77 342.844 L 137.061 342.844 L 298.415 73.92 L 459.77 342.844 Z"
              transform="matrix(0, -1, 1, 0, 90.033493, 506.797485)"
            ></path>
          </svg>
        </Box>
      </Box> */}
      <div
        className="body__container"
        style={{
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            // mt: 22,
          }}
        >
          <Grid xs={12} md={6}>
            <Box
              mr={10}
              mb={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                ml: { xs: 20, md: 39 },
              }}
            >
              <img
                src={notifications}
                className={classes.section3__images}
                alt=""
              />
              <H5Style sx={{ fontWeight: "bold" }}>Notifications</H5Style>
              <PStyle sx={{ textAlign: "right" }}>
                Our systems make it easy to send Email/SMS notifications to
                customers and staff members for a variety of purposes.
              </PStyle>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              ml={10}
              mb={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                mr: { xs: 20, md: 39 },
              }}
            >
              <img src={employee} alt="" className={classes.section3__images} />
              <H5Style sx={{ fontWeight: "bold" }}>Employee</H5Style>
              <PStyle sx={{ textAlign: "left" }}>
                Manage your staff including their personal information,
                schedule, break time, vacation time and allocated services they
                can provide.
              </PStyle>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              mr={10}
              mb={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                ml: { xs: 20, md: 39 },
              }}
            >
              <img src={coupon} className={classes.section3__images} alt="" />
              <H5Style sx={{ fontWeight: "bold" }}>Coupon</H5Style>
              <PStyle sx={{ textAlign: "right" }}>
                This innovative product gives you specific discount coupons for
                your customers, with discounts of a certain percentage or
                amount.
              </PStyle>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              ml={10}
              mb={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                mr: { xs: 20, md: 39 },
              }}
            >
              <img src={pin} alt="" className={classes.section3__images} />
              <H5Style sx={{ fontWeight: "bold" }}>Multi Location</H5Style>
              <PStyle sx={{ textAlign: "left" }}>
                If your company is located simultaneously in several locations,
                you can combine your points on our system.
              </PStyle>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              mr={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                ml: { xs: 20, md: 39 },
              }}
            >
              <img src={reminder} className={classes.section3__images} alt="" />
              <H5Style sx={{ fontWeight: "bold" }}>Reminder</H5Style>
              <PStyle sx={{ textAlign: "right" }}>
                Missed appointments cost you time and money. It will always be
                beneficial for you to inform and remind your customers about the
                appointment in advance with SMS, Email notification.
              </PStyle>
            </Box>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              ml={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                mr: { xs: 20, md: 39 },
              }}
            >
              <img src={gear} alt="" className={classes.section3__images} />
              <H5Style sx={{ fontWeight: "bold" }}>
                Google meet Integration
              </H5Style>
              <PStyle sx={{ textAlign: "left" }}>
                We are fully automate your online Google meetings via our
                system.
              </PStyle>
            </Box>
          </Grid>
        </Box>
      </div>
      {/* forth section */}
      <Divider
        sx={{
          width: "100%",
          mt: "100px",
          mb: "100px",
          border: "1px #E5E5FD solid",
        }}
      />
      <div
        className="body__container"
        style={{
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <RegisterToBeNotified
            countriesList={countriesList}
            citiesList={citiesList}
            industriesList={industriesList}
            handleCitiesList={handleCitiesList}
          />
          <Grid xs={12} md={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Grid xs={12} md={6}>
              <Box ml={6}>
                <H1Style
                  sx={{
                    marginBottom: 4,
                    mt: 6,
                  }}
                >
                  For user's
                </H1Style>
                <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                  <H5Style>Find business</H5Style>
                  <PStyle
                    sx={{
                      marginRight: 2,
                    }}
                  >
                    Discover & connect with great local businesses in your local
                    neighborhood like beauty salon, Photography, home cleaner
                    and more.
                  </PStyle>
                  <H5Style
                    sx={{
                      mt: 3,
                    }}
                  >
                    Review Listing
                  </H5Style>
                  <PStyle
                    sx={{
                      marginRight: 2,
                    }}
                  >
                    Get valuable insights about listings and tell other readers
                    about your experiences by leaving reviews for businesses.
                  </PStyle>
                  <H5Style
                    sx={{
                      mt: 3,
                    }}
                  >
                    Make appointment
                  </H5Style>
                  <PStyle
                    sx={{
                      marginRight: 2,
                      mb: { xs: 5, md: 0 },
                    }}
                  >
                    Easily setup an appointment directly from the business
                    listing page using our integrated booking options.
                  </PStyle>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <Box sx={{ ml: { md: 2, xs: 0 } }}>
                <img src={users} alt="" className={classes.forUsers__image} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
};

export default Subscribe;
