import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useGetInfo from "./hook/useGetInfo";
import Coupon from "./Coupon";
import GiftCard from "./GiftCard";
import Summary from "./Summary";
import CircularProgress from "@mui/material/CircularProgress";

const Information = ({ stepId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { step, allStep, setAllStep, infoList, setPriceInfo } = useGetInfo();

  const handelData = (info) => {
    if (info) {
      const data = allStep.map((step) =>
        step.id === stepId ? { ...step, isOpen: info } : step
      );
      setAllStep(data);
    }
  };

  const price = () => {
    let count = 0;
    infoList.forEach((ans) => {
      if (ans.price) {
        count = ans.price + count;
      }
    });
    return count;
  };
  const VAT = price() - price() * (100 / (100 + 7.6));

  useEffect(() => {
    setPriceInfo([{ price: price() }]);
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ paddingX: "15px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: "1px solid #ddd" }}
          >
            <Typography
              sx={{ padding: "5px", fontWeight: "600", cursor: "pointer" }}
              borderBottom={step.isOpen === "Summary" && "3px solid blue"}
              color={step.isOpen === "Summary" ? "#000" : "#ddd"}
              onClick={() => handelData("Summary")}
            >
              Summary
            </Typography>
            <Typography
              sx={{ padding: "5px", fontWeight: "600", cursor: "pointer" }}
              borderBottom={step.isOpen === "Coupon" && "3px solid blue"}
              color={step.isOpen === "Coupon" ? "#000" : "#ddd"}
              onClick={() => handelData("Coupon")}
            >
              Coupon
            </Typography>
            <Typography
              sx={{ padding: "5px", fontWeight: "600", cursor: "pointer" }}
              borderBottom={step.isOpen === "GiftCard" && "3px solid blue"}
              color={step.isOpen === "GiftCard" ? "#000" : "#ddd"}
              onClick={() => handelData("GiftCard")}
            >
              Gift Card
            </Typography>
          </Box>
          {step.isOpen === "Summary" && <Summary VAT={VAT} />}
          {step.isOpen === "Coupon" && <Coupon VAT={VAT} />}
          {step.isOpen === "GiftCard" && <GiftCard VAT={VAT} />}
        </Box>
      )}
    </>
  );
};

export default Information;
