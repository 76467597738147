import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CustomTypography from "../../components/CustomTypography";
import CustomTextField from "../../components/CustomTextField";
import support from "../../assets/images/support/support.jpg";
import classes from "./Support.module.css";
import { TextField } from "@mui/material";
import CustomAutocomplete from "../../components/CustomAutocomplete";
import CustomButton from "../../components/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { contactSupportAPI } from "../../services";
import { goUpThePage } from "../../utils/goUpThePage";
import CustomPhoneField from "../../components/CustomPhoneField";
import H1Style from "../../components/textComponents/H1Style";
import H2Style from "../../components/textComponents/H2Style";

const topics = [
  "I have a question about getting started",
  "I need help setting up my team's workflow",
  "Billing Questions",
  "I have questions about automations/Integrations",
  "Apps/Api Marketplace questions",
  "Something is not working",
  "Legal and Security",
  "I am having trouble logging in",
  "I am having trouble signing up",
  "I have a feature request",
  "Mobile",
  "Other",
];

function ContactSupport() {
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    topic: "",
    question: "",
    inquiry: "",
  });

  const [formError, setFormError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    topic: false,
    question: false,
    inquiry: false,
  });

  const handleTextFieldChange = (fieldName) => (e) => {
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: fieldName === "phone" ? e : e.target.value,
    }));
    setFormError((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
  };

  const handleAutoCompleteField = (fieldName) => (e, value) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }));
    setFormError((prevState) => ({
      ...prevState,
      [fieldName]: false,
    }));
  };

  const handleSubmit = async () => {
    let isError = false;

    for (const key in form) {
      if (!form[key]) {
        isError = true;
        setFormError((prevState) => {
          return { ...prevState, [key]: true };
        });
      } else {
        if (form[key].length === 0) {
          isError = true;
          setFormError((prevState) => {
            return { ...prevState, [key]: true };
          });
        }
      }
    }

    if (!isError) {
      try {
        const response = await contactSupportAPI(form);
        if (response.status === 200) {
          setForm({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            topic: "",
            question: "",
            inquiry: "",
          });
          setIsSent(true);
        }
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleNavigation = (link) => () => {
    navigate(link);
    goUpThePage();
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: "100%", md: "auto" },
        justifyContent: "center",
        paddingLeft: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
        paddingRight: {
          xs: "16px !important",
          sm: "40px !important",
          md: "40px !important",
        },
      }}
    >
      <div className="body__container">
        <H1Style
          sx={{
            // fontWeight: "bold",
            marginTop: 12,
            marginBottom: 1,
            // fontSize: 50,
            // color: "#242424",
          }}
          variant="h1"
          textAlign={"center"}
        >
          We're here for you
        </H1Style>
        <Box display="flex" justifyContent={"center"} mb={8} flexWrap={"wrap"}>
          <H2Style
            sx={
              {
                // fontSize: 30,
              }
            }
            textAlign={"center"}
          >
            Get quick answer to your questions or
          </H2Style>
          <Link
            to={"/contact"}
            style={{
              textDecoration: "none",
            }}
          >
            <H2Style
              sx={{
                // fontSize: 30,
                whiteSpace: "nowrap",
                mx: 1,
                // color: "#1976D2",
                textDecoration: "underline",
              }}
              textAlign={"center"}
            >
              contact
            </H2Style>
          </Link>

          <H2Style
            sx={{
              // fontSize: 30,
              whiteSpace: "nowrap",
            }}
            textAlign={"center"}
          >
            our team
          </H2Style>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Grid xs={12} md={6}>
            <Box
              sx={{
                mr: { xs: 0, md: 4 },
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  position: "relative",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={support}
                    alt=""
                    className={classes.support__image}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    position: "absolute",
                    zIndex: 99,
                    top: "10%",
                  }}
                >
                  <Box>
                    <CustomTypography
                      sx={{
                        fontWeight: "900",
                        fontSize: 28,
                        color: "white",
                        zIndex: 99,
                        ml: "2.5vw",
                        textShadow: "0px 0px 10px rgb(0,0,0,0.2)",
                      }}
                      variant="h2"
                    >
                      Have a question?
                    </CustomTypography>
                    <Box
                      sx={{
                        borderBottom: "5px solid white",
                        width: "100px",
                        ml: "2.5vw",
                        mt: 1,
                      }}
                    ></Box>
                    <CustomTypography
                      sx={{
                        fontWeight: "700",
                        fontSize: 18,
                        color: "white",
                        zIndex: 99,
                        ml: "2.5vw",
                        textShadow: "0px 0px 10px rgb(0,0,0,0.2)",
                        mt: 4,
                      }}
                    >
                      Our team is here for you 24/7.
                    </CustomTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={6}
            sx={
              {
                // mx: { xs: 2, md: 0 },
              }
            }
          >
            <Box
              sx={{
                // pr: { xs: 0, md: "60px" },
                // ml: { xs: 0, md: "50px" },
                mt: { xs: 10, md: 0 },
                width: "100%",
                height: "100%",
              }}
            >
              <div className={classes.formContainer}>
                <div className={classes.formContainer__form}>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Grid xs={11.5} lg={5.5} sx={{ mr: "auto", ml: "auto" }}>
                      <CustomTextField
                        error={formError.firstName}
                        value={form.firstName}
                        onChange={handleTextFieldChange("firstName")}
                        customData={{
                          label: "First name",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                      />
                    </Grid>
                    <Grid
                      xs={11.5}
                      lg={5.5}
                      sx={{ mr: "auto", ml: "auto", mt: { xs: 3, lg: 0 } }}
                    >
                      <CustomTextField
                        error={formError.lastName}
                        value={form.lastName}
                        onChange={handleTextFieldChange("lastName")}
                        customData={{
                          label: "Last name",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                      />
                    </Grid>

                    <Grid
                      xs={11.5}
                      lg={5.5}
                      ml={1}
                      sx={{ mr: "auto", ml: "auto", mt: 1 }}
                    >
                      <CustomTextField
                        error={formError.email}
                        value={form.email}
                        onChange={handleTextFieldChange("email")}
                        customData={{
                          label: "Email",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                        placeholder={"name@company.com"}
                      />
                    </Grid>
                    <Grid
                      xs={11.5}
                      lg={5.5}
                      ml={1}
                      sx={{ mr: "auto", ml: "auto", mt: 1 }}
                    >
                      <CustomPhoneField
                        error={formError.phone}
                        value={form.phone || "+46"}
                        onChange={handleTextFieldChange("phone")}
                        customData={{
                          label: "Phone",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                      />
                    </Grid>
                    <Grid xs={11.5} sx={{ mr: "auto", ml: "auto", mt: 1 }}>
                      <CustomAutocomplete
                        error={formError.topic}
                        value={form.topic}
                        onChange={handleAutoCompleteField("topic")}
                        customData={{
                          label: "Please select a topic",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                        options={topics}
                      />
                    </Grid>
                    <Grid
                      xs={11.5}
                      ml={1}
                      sx={{ mr: "auto", ml: "auto", mt: 1 }}
                    >
                      <CustomTextField
                        error={formError.question}
                        value={form.question}
                        onChange={handleTextFieldChange("question")}
                        customData={{
                          label: "What is your question?",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                      />
                    </Grid>

                    <Grid
                      xs={11.5}
                      ml={1}
                      sx={{ mr: "auto", ml: "auto", mt: 1 }}
                    >
                      <CustomTextField
                        error={formError.inquiry}
                        value={form.inquiry}
                        onChange={handleTextFieldChange("inquiry")}
                        customData={{
                          label: "Tell us how can we help",
                          labelSx: {
                            fontWeight: "normal",
                            fontSize: "16px",
                          },
                        }}
                        required
                        multiline
                        rows={3}
                        maxRows={3}
                        inputProps={{
                          style: {
                            height: "100%",
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      xs={11.5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        mr: "auto",
                        ml: "auto",
                      }}
                    >
                      <CustomTypography sx={{ fontSize: "16px" }} mb={2} mt={4}>
                        By clicking submit, I acknowledge receipt of the upnize{" "}
                        <Link
                          className={classes.linkStyle}
                          to="/privacy-policy"
                          onClick={() => {
                            goUpThePage();
                          }}
                        >
                          Privacy policy
                        </Link>
                      </CustomTypography>
                      <CustomTypography sx={{ fontSize: "16px" }} mb={2}>
                        {isSent ? (
                          "Thanks! Support will contact you soon."
                        ) : (
                          <div style={{ height: "19.19px" }}></div>
                        )}
                      </CustomTypography>
                      <CustomButton
                        onClick={handleSubmit}
                        size={"large"}
                        sx={{ width: "180px" }}
                      >
                        submit
                      </CustomButton>
                    </Grid>
                  </Box>
                </div>
              </div>
            </Box>
          </Grid>
        </Box>
      </div>
    </Grid>
  );
}

export default ContactSupport;
