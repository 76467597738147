import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useGetInfo from "./hook/useGetInfo";

const Footer = () => {
  const navigate = useNavigate();
  const {
    infoList,
    step,
    currentStep,
    allStep,
    handelNext,
    handelBack,
    scrollLeft,
    scrollRight,
    scrollRef,
  } = useGetInfo();
  const findCurretInfo = () => {
    const findInfo = infoList.find((info) => info.id === step?.id);
    if (findInfo) return false;
    if (step?.type === "extraService") {
      return false;
    }
    return true;
  };
  const handleNextStep = () => {
    if (allStep.length - 1 === currentStep) {
      handelNext();
      // navigate("/payment");
      return;
    }
    handelNext();
    scrollRight(70);
  };
  const handleBackStep = () => {
    if (allStep.length === currentStep) {
      handelBack();
      navigate("/");
      return;
    }
    handelBack();
    scrollLeft(70);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #E2E5FC",
        height: "80px",
        alignItems: "center",
        // width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // width: "940px",
          width: "100%",
          // maxWidth: "600px",
          // width: "600px",
          // padding: "0 1rem",
          padding: "0 23px",
          // gap: "10px",
        }}
      >
        <Button
          size="small"
          onClick={handleBackStep}
          disabled={0 === currentStep}
          sx={[
            {
              "&:hover": {
                color: "#FFFFFF",
                background: "#939AB5",
              },
              "&:disabled": {
                backgroundColor: "#ddd",
              },
              background: "#939AB5",
              color: "#FFFFFF",
              height: "45px",
              width: "145px",
            },
          ]}
        >
          Back
        </Button>
        {step?.type === "auth" ? (
          <div />
        ) : allStep.length === currentStep ? (
          <div />
        ) : (
          <Button
            variant="contained"
            sx={[
              {
                "&:hover": {
                  color: "#FFFFFF",
                  background: "#4B64E9",
                },
                "&:disabled": {
                  backgroundColor: "#ddd",
                },
                background: "#4B64E9",
                color: "#FFFFFF",
                height: "45px",
                width: "145px",
                display: step?.type === "payment" ? "none" : "",
              },
            ]}
            onClick={handleNextStep}
            disabled={allStep.length - 2 !== currentStep && findCurretInfo()}
          >
            {allStep.length - 2 === currentStep ? "Confirm" : "Next Step"}
          </Button>
        )}
      </div>
    </Box>
  );
};

export default Footer;
