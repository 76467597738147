import React from "react";
import { Typography } from "@mui/material";

const PStyle = (props) => {
  return (
    <Typography
      variantMapping="p"
      {...props}
      sx={{
        color: "#3E3B4E",
        fontSize: "16px",
        my: "15px",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default PStyle;
