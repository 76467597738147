import InventoryIcon from "@mui/icons-material/Inventory";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import Groups2RoundedIcon from "@mui/icons-material/Groups2Rounded";
import PollRoundedIcon from "@mui/icons-material/PollRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import NearMeRoundedIcon from "@mui/icons-material/NearMeRounded";
import LoyaltyRoundedIcon from "@mui/icons-material/LoyaltyRounded";
import CardGiftcardRoundedIcon from "@mui/icons-material/CardGiftcardRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import StoreIcon from "@mui/icons-material/Store";
import ReviewsIcon from "@mui/icons-material/Reviews";

export const pages = [
  { text: "Dashboard", link: "/", icon: <DashboardRoundedIcon /> },
  {
    text: "Reports",
    link: "/report",
    icon: <QueryStatsRoundedIcon />,
  },
  {
    text: "Appointments",
    link: "/appointment",
    icon: <AccessTimeRoundedIcon />,
    // child: [
    //   {
    //     text: "Calender",
    //     link: "/appointments/calender",
    //   },
    // ],
  },
  {
    text: "Calendar",
    link: "/calendar",
    icon: <CalendarMonthRoundedIcon />,
  },
  {
    text: "Payments",
    link: "/payment",
    icon: <AccountBalanceWalletRoundedIcon />,
    // child: [
    //   { text: "Customers", link: "/payment/customer" },
    //   { text: "Services", link: "/payment/service" },
    //   { text: "Staff", link: "/payment/staff" },
    //   { text: "Locations", link: "/payment/location" },
    // ],
  },

  { text: "Customers", link: "/customer", icon: <Groups2RoundedIcon /> },
  { text: "Services", link: "/service", icon: <PollRoundedIcon /> },
  { text: "Staff", link: "/staff", icon: <BadgeRoundedIcon /> },

  { text: "Reviews", link: "/review", icon: <ReviewsIcon /> },

  { text: "Locations", link: "/location", icon: <NearMeRoundedIcon /> },
  { text: "Taxes", link: "/tax", icon: <PercentRoundedIcon /> },
  { text: "Coupons", link: "/coupon", icon: <LoyaltyRoundedIcon /> },
  { text: "Giftcards", link: "/giftcard", icon: <CardGiftcardRoundedIcon /> },
  { text: "Invoices", link: "/invoice", icon: <DescriptionRoundedIcon /> },
  {
    text: "Notifications",
    link: "/notification",
    icon: <NotificationsRoundedIcon />,
  },
  { text: "Workflows", link: "/workflow", icon: <AccountTreeRoundedIcon /> },
  {
    text: "Role Manager",
    link: "/role",
    icon: <PersonAddIcon />,
  },

  { text: "Settings", link: "/setting", icon: <SettingsRoundedIcon /> },
];

export const customerPages = [
  { text: "Dashboard", link: "/", icon: <DashboardRoundedIcon /> },
  { text: "Appointments", link: "/appointments", icon: <WatchLaterIcon /> },
  { text: "Providers", link: "/providers", icon: <StoreIcon /> },

  { text: "Invoices", link: "/invoice", icon: <DescriptionRoundedIcon /> },
  { text: "Settings", link: "/setting", icon: <SettingsRoundedIcon /> },
];
