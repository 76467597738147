import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BookingComponent from '../../../components/BookingWidget/BookingDialog/BookingComponent';
import CustomizedAccordions from './AccordionComponent';
import useGetInfo from './../../../components/BookingWidget/hook/useGetInfo';
import H1Style from '../../../components/textComponents/H1Style';
import PStyle from '../../../components/textComponents/PStyle';
import H5Style from '../../../components/textComponents/H5Style';
import H6Style from '../../../components/textComponents/H6Style';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from '../../../constants/endpoint';

const FoodMenu = () => {
  const { step } = useGetInfo();
  const { id, loctionId } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [cats, setCats] = useState([]);
  const [active, setActive] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const { data: response } = await axios.get(
          'https://t-backend.upnize.com/tenant/restaurant/menus',
          {
            headers: {
              TenantId: id,
            },
          }
        );
        setMenus(response);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchMenu();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: response } = await axios.get(
          'https://t-backend.upnize.com/tenant/restaurant/items',
          {
            headers: {
              TenantId: id,
            },
          }
        );
        setData(response);
        console.log(response);
        let uniqueIds = [];
        let categories = [];
        response.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.categoryId);

          if (!isDuplicate) {
            uniqueIds.push(element.categoryId);
            categories.push(element.category);
            return true;
          }

          return false;
        });

        setCats(categories);
        console.log('cats------', categories);
        setActive(categories[0]['id']);
        setSelectedData(
          response.filter((i) => i.category.id === categories[0]['id'])
        );
      } catch (error) {}
      setLoading(false);
    };

    fetchData();
  }, []);

  const onClick = (id) => {
    setActive(id);
    setSelectedData(data.filter((i) => i.category.id === id));
  };
  const getWeekNum = () => {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);

    return weekNumber;
  };

  return (
    <Box>
      <Box className="body__container">
        <H1Style
          sx={{
            marginBottom: '20px',
          }}
        >
          Lunch, Week {getWeekNum()}
        </H1Style>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: '30px',
          }}
        >
          <Box
            md={9}
            sm={12}
            xs={12}
            sx={{
              flex: '1 1 auto',
            }}
          >
            <CustomizedAccordions menus={menus} />
          </Box>
          <Box
            md={3}
            sm={12}
            xs={12}
            sx={{
              width: '100%',
              flex: '0 0 350px',
            }}
          >
            <BookingComponent step={step} />
          </Box>
        </Grid>
        <Divider
          sx={{
            width: '100%',
            mt: 6,
            mb: 8,
            border: '1px #E5E5FD solid',
          }}
        />

        <Grid>
          <H1Style sx={{ marginBottom: '24px' }}>Food Menu</H1Style>

          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '25px',
              }}
            >
              {loading && <div>Loading...</div>}
              {!loading &&
                cats.map((val, index) => (
                  <>
                    <Box
                      sx={{
                        borderRadius: '20px',
                        textAlign: 'center',
                        padding: '8px',
                        cursor: 'pointer',
                        width: '120px',
                        backgroundColor:
                          active === val.id ? '#1976d2' : '#F7F8FB',
                        color: active === val.id ? '#fff' : '#000',
                        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                        textTransform: 'capitalize',
                      }}
                      onClick={(e) => {
                        onClick(val.id);
                      }}
                    >
                      {val.name}
                    </Box>
                  </>
                ))}
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '32px 0px',
                marginTop: '70px',
              }}
            >
              {selectedData.map((item) => (
                <Grid
                  md={6}
                  sx={{
                    display: 'flex',
                    gap: '25px',
                  }}
                >
                  <Box
                    sx={{
                      width: '64px',
                      height: '64px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      src={`${BACKEND_URL}/${item.image}`}
                      alt=""
                    />
                  </Box>
                  <Box>
                    <H6Style
                      sx={{
                        color: '#3E3B4E',
                      }}
                    >
                      {item.title}
                    </H6Style>
                    <PStyle
                      sx={{
                        m: 0,
                      }}
                    >
                      {item.description}
                    </PStyle>
                    <H6Style
                      sx={{
                        color: '#3E3B4E',
                      }}
                    >
                      Pris: {item.price}
                    </H6Style>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FoodMenu;
