import DomainIcon from '@mui/icons-material/Domain';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Replay5Icon from '@mui/icons-material/Replay5';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InterestsIcon from '@mui/icons-material/Interests';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PercentRoundedIcon from '@mui/icons-material/PercentRounded';
import GroupIcon from '@mui/icons-material/Group';
import DescriptionIcon from '@mui/icons-material/Description';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GoogleIcon from '@mui/icons-material/Google';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import WidgetsIcon from '@mui/icons-material/Widgets';

export const featuresList = [
    {
        title: "Custom domain",
        icon: (className) => <DomainIcon className={className} />,
        description: "Custom domain option for the tenant so they can point their domains using CNAME or DNS configuration",
        display: true,
    },
    {
        title: "Insightful dashboard",
        icon: (className) => <DashboardIcon className={className} />,
        description: "Our dashboards feature provides an overview of current appointment bookings within your business. You can view the details of scheduled appointments, including their status, the number of appointments, the total amount of booked time, and the total bill amount. This allows you to stay up-to-date on the status of your appointments and manage your business efficiently.",
        display: true,
    },
    {
        title: "Reporting module",
        icon: (className) => <QueryStatsIcon className={className} />,
        description: "Our reporting module empowers you to improve your business and booking system through data-driven insights. With the ability to view and analyze your business's profitability by location, staff, and services, you can make informed decisions to optimize your operations. The graphical representation of the reports makes it easy to understand and interpret the data, giving you the ability to identify trends and areas for improvement. By being able to measure your business's performance, you can take the necessary steps to improve it, resulting in a more successful business overall.",
        display: true,
    },
    {
        title: "Calendar module",
        icon: (className) => <CalendarMonthIcon className={className} />,
        description: "Our Calendar module allows you to keep track of your staff's schedule in real-time through an intuitive and user-friendly interface. You can easily switch between daily, weekly, or monthly views and even have the option of viewing the schedule in a list format. With the ability to filter appointments by location, service, and staff, you can quickly identify and manage conflicts, keeping your schedule running smoothly. Additionally, the module is up-to-date, allowing you to stay informed about changes or updates as they happen.",
        display: true,
    },
    {
        title: "Multi level categories",
        icon: (className) => <CategoryIcon className={className} />,
        description: "You can create your services with multi-level service categories. If you have a lot of services, the list mode will be beneficial for you. With the list view, you can list your services in a single row.",
        display: true,
    },
    {
        title: "Services",
        icon: (className) => <DesignServicesIcon className={className} />,
        description: "With the perfect structured list, you can easily categorise your business services.",
        display: true,
    },
    {
        title: "Recurring appointments",
        icon: (className) => <Replay5Icon className={className} />,
        description: "With the UPNIZE appointment, you can keep the booking of your repetitive services in a convenient and superb interface. With the wide-ranging, flexible configurations, you can integrate every type of your business’s recurring service into your system.",
        display: true,
    },
    {
        title: "Group appointment",
        icon: (className) => <GroupAddIcon className={className} />,
        description: "For group appointments, you can enable a single user registration for multiple seats. Bring people with you feature can be used within group appointments to remove separate appointments for each attendant.",
        display: true,
    },
    {
        title: "Service extras",
        icon: (className) => <InterestsIcon className={className} />,
        description: "UPNIZE lets you connect major services with sub-service categories. This feature is service extras in our admin dashboard.",
        display: true,
    },
    {
        title: "Restaurant menu module",
        icon: (className) => <RestaurantMenuIcon className={className} />,
        description: "Creating a restaurant menu on Upnize allows you to showcase your lunch and dinner options to customers in Upnize Network. By including images, titles, descriptions, and food categories in admin, you can easily organize your menu and make it simple for customers to find the dishes they are looking for. With the ability to view and manage your menu online, you can easily make updates and changes as needed, ensuring that your customers always have access to the most current information about your food offerings. Furthermore, by Upnize network platform it allows your customers to place an order or booking a table easily and you will be able to manage them in an efficient way.",
        display: true,
    },
    {
        title: "Product sale module",
        icon: (className) => <ShoppingCartIcon className={className} />,
        description: "This feature allows companies to cross-sell and upsell products, increasing revenue and customer satisfaction. Companies can utilize this feature to promote and sell their products, with the added benefit of being able to track sales and customer interactions. It also allows customers to book appointment and purchase products directly on our network platform, making it easy and hassle-free for both parties.",
        display: true,
    },
    {
        title: "Multiple locations",
        icon: (className) => <AddLocationAltIcon className={className} />,
        description: "If your company is located simultaneously in several locations, you can combine your points on a system by adding each other separately. You can accept separate bookings for each area, and you can add staff for locations and by comparing your locations, you can create a rating schedule for creating competition between.",
        display: true,
    },
    {
        title: "Taxes",
        icon: (className) => <PercentRoundedIcon className={className} />,
        description: "With the Taxes module, you can create taxes and set the location and service filter to them.",
        display: true,
    },
    {
        title: "Staff",
        icon: (className) => <GroupIcon className={className} />,
        description: "Upnize Appointment Booking system supports multi-staff. You can add your Staff from different locations to the system.",
        display: true,
    },
    {
        title: "Invoice",
        icon: (className) => <DescriptionIcon className={className} />,
        description: "With UPNIZE Booking SaaS you will have a chance to send invoices about your services. However, preparing invoices is quite a complex and time taking task. With UPNIZE Invoice feature, everything will be created automatically and fast in PDF format.",
        display: true,
    },
    {
        title: "Coupons",
        icon: (className) => <LoyaltyIcon className={className} />,
        description: `We allows you to create and offer special discounts to your customers during specific time frames. This can be used to target specific groups of customers such as staff members, certain services, or certain locations. This feature is a great way to incentivize customer engagement and drive sales.
        For example, you can offer a discount on a specific service during the weekdays or a promotional offer to attract customer in a specific location. Additionally, you can also use this feature to reward loyal customers or incentivize new customers to try out your services.
        By offering special discounts, you can increase customer satisfaction, improve retention, and increase revenue for your business. This feature makes it easy for you to promote your special offers and keep track of how well they are performing, allowing you to make informed decisions on future promotions.`,
        display: true,
    },
    {
        title: "Gift card",
        icon: (className) => <CardGiftcardIcon className={className} />,
        description: "The Giftcard you have created will correspond to a certain amount, and if the Customer uses this gift card when making an appointment, the money paid will be out of the gift card balance.",
        display: true,
    },
    {
        title: "Workflow module",
        icon: (className) => <AccountTreeRoundedIcon className={className} />,
        description: `With the Workflow module, you can automate every action related to your meetings and build your business processes. For example, an email is sent to the customer when an appointment is received.
        You can send different types of notifications through the Workflow module. Actions: New registration, membership, withdrawal, payment accepted, etc. Triggers: Send an email.
        Upcoming features: send an SMS, send a WhatsApp message, etc.`,
        display: true,
    },
    {
        title: "Payment gateway module",
        icon: (className) => <CreditCardIcon className={className} />,
        description: `Owner Admin can add Stripe, paypal as a gateway for their customers.
        If you want to get your recipes on the spot from your customers, you can go with local payments. You can activate/deactivate the local payment method as you wish in settings.`,
        display: true,
    },
    {
        title: "Breaks, days-off, etc.",
        icon: (className) => <HolidayVillageIcon className={className} />,
        description: "You can limit getting appointment bookings for a time. We built breaks, day-off, holidays to simplify this process on your behalf. This feature allows you to block certain time slots on your calendar thus avoiding getting appointment bookings for that period.",
        display: true,
    },
    {
        title: "Export & import data",
        icon: (className) => <ImportExportIcon className={className} />,
        description: "Import and export csv file.",
        display: true,
    },
    {
        title: "Google calendar sync",
        icon: (className) => <GoogleIcon className={className} />,
        description: `To simplify your time management and remove any confusion, once activated, you can as owner automatically sync all your Reserva appointments to Google Calendar and import Google Calendar events to System calendar. So, the busy times on your Google Calendar will be marked as busy on your booking calendar.
        You can further customize Reserva Google Calendar event descriptions by adding keywords.`,
        display: true,
    },
    {
        title: "Role manager",
        icon: (className) => <PersonAddIcon className={className} />,
        description: "This feature gives managers the ability to assign different levels of access and permissions to staff members, ensuring that sensitive information and tasks are only accessible to authorized personnel. This feature is particularly useful for large companies with multiple departments, or for businesses with a high number of staff members. With the ability to create and manage custom roles, companies can ensure that their staff are able to perform their tasks efficiently and effectively. This helps to improve overall productivity, security, and compliance for the company.",
        display: true,
    },
    {
        title: "Custom appointment statuses",
        icon: (className) => <SignalWifiStatusbar4BarIcon className={className} />,
        description: "This feature lets you create unlimited statuses for the booked appointments throughout the appointment process.",
        display: true,
    },
    {
        title: "Booking widget",
        icon: (className) => <WidgetsIcon className={className} />,
        description: `Booking widget is a powerful tool for businesses looking to increase online bookings and customer convenience. This widget can be easily added to a website by using an iFrame, allowing customers to book directly from the company's website. This makes it easy for customers to find and book available appointments, without having to navigate away from the website.
        By providing the widget feature, businesses can reduce the number of calls and emails from customers enquiring about availability and increase the booking conversions. It also makes the process easy for customers as they can book appointment at any time directly from their website. This can also save time for both customers and staff by reducing the need for manual booking and communication. Overall it is a win-win situation for both customer and business.`,
        display: true,
    },
    {
        title: "Marketplace",
        icon: (className) => <DesignServicesIcon className={className} />,
        description: `Configure your website settings and create custom pages directly under your domain. This gives you full control over what is visible on your website, and the flexibility to make updates and changes as needed.
        With this feature, you can create pages for your services, restaurant menu, product list, photo gallery, reviews, and much more. It also gives you the ability to organize and present the information in an effective way that makes sense for your business. This can help improve user experience and increase engagement, resulting in more bookings and sales. Additionally, creating custom pages under your own domain can help with search engine optimization and enhance the overall credibility of your business.`,
        display: true,
    },
]
