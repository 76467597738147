import { Box, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useGetInfo from "./hook/useGetInfo";

const StaffOption = ({ options, handelClick, stepId, isChecked }) => {
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading } = useGetInfo();

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {options.map((option, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "4px 20px",
                border: "1px solid #ddd",
                margin: "10px",
                height: "60px",
                cursor: "pointer",
              }}
              onClick={() => handelClick(option, stepId, "Staff")}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    marginRight: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    // src={option.img}
                    // alt={option.name}
                    width="50"
                    height="50"
                  />
                </Box>
                <Box>
                  <Typography variant="p">
                    {`${option?.User?.firstName} ${option?.User?.lastName}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      color: "gray",
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="p"
                      fontSize="12px"
                      // sx={{ width: { xs: "100%", sm: "10rem", md: "15rem" } }}
                    >
                      {option.User?.phone}
                    </Typography>
                    <Typography variant="p" fontSize="12px">
                      {option.User?.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Checkbox
                sx={{ hight: "80px" }}
                checked={isChecked(option, stepId)}
                classes={{ root: "checkbox" }}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default StaffOption;
