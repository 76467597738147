import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import useGetInfo from "./hook/useGetInfo";

const Coupon = ({ VAT }) => {
  const { setPriceInfo, priceInfo, total } = useGetInfo();
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState("");
  const findCoupon = priceInfo.find((info) => info.coupon);
  // const findTotal = priceInfo.find((info) => info.price);
  const handelChnage = (e) => {
    setCoupon(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (coupon.length === 0) {
      setError("Coupon is Required");
    }
    try {
      // factch POST for submit coupon
      // GET RESPONSE AND SET DATA

      if (coupon === "COUPON") {
        setError("");
        const data = { id: 10, type: "Coupon", discount: 10 };
        setPriceInfo([...priceInfo, { coupon: data.discount }]);
      } else {
        setError("Your Coupon Code is not Valid");
      }
    } catch (error) {}
  };
  console.log(findCoupon);
  return (
    <Box marginY="15px">
      <form onSubmit={handleSubmit}>
        <TextField
          placeholder="Coupon Code"
          onChange={handelChnage}
          value={coupon}
          sx={{ width: { md: "45%", xs: "100%" } }}
          error={!!error}
          helperText={error ? error : ""}
          inputProps={{
            style: {
              height: "45px",
              padding: "0 10px",
            },
          }}
        />
        <Button
          variant="contained"
          sx={{
            width: "15%",
            height: "45px",
            marginLeft: { xs: "0", md: "18px" },
            marginTop: { xs: "20px", md: "0" },
            padding: { xs: "10px", md: "14px" },
          }}
          type="submit"
        >
          OKey
        </Button>
      </form>
      {findCoupon && (
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
        >
          <Box marginY="7px">
            <Typography
              color="gray"
              variant="p"
              sx={{ textTransform: "uppercase" }}
              fontWeight="400"
              fontSize="16px"
            >
              {Object.keys(findCoupon)}
            </Typography>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ fontSize: "16px", color: "#4B64E9" }}
            >
              ${findCoupon.coupon}
            </Typography>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ margin: "1rem 0", borderBottom: "1px solid #ddd" }}
      >
        <Box marginY="7px">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Total Price
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${total}
          </Typography>
        </Box>
        <Box marginY="7px" textAlign="end">
          <Typography
            color="gray"
            variant="p"
            sx={{ textTransform: "uppercase" }}
            fontWeight="400"
            fontSize="16px"
          >
            Vat
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: "16px", color: "#4B64E9" }}
          >
            ${VAT.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Coupon;
