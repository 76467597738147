import { Box, Divider } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import HelpCenterBodySection from "../../components/HelpCenter/BodySection";
import HelpCenterSearch from "../../components/HelpCenter/Search";
import HelpCenterSideSection from "../../components/HelpCenter/SideSection";
import HelpCenterWelcomeSection from "../../components/HelpCenter/WelcomeSection";
import Highlighted from "../../components/Highlighted";
import classes from "./HelpCenter.module.css";

const HelpCenter = () => {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 900px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div>
      <HelpCenterWelcomeSection />

      <Box
        className={classes.mainSection}
        sx={{
          minWidth: { xs: "100%", md: "100%" },
          justifyContent: "center",
          paddingLeft: {
            xs: "16px !important",
            sm: "40px !important",
            md: "40px !important",
          },
          paddingRight: {
            xs: "16px !important",
            sm: "40px !important",
            md: "40px !important",
          },
        }}
      >
        <HelpCenterBodySection />
        {matches && (
          <Divider
            style={{ width: "100%", margin: "20px 0" }}
            variant="middle"
          />
        )}
        <HelpCenterSideSection />
      </Box>
    </div>
  );
};

export default HelpCenter;
