import React from "react";
import { Typography } from "@mui/material";

const H3Style = (props) => {
  return (
    <Typography
      {...props}
      sx={{
        color: "#3E3B4E",
        fontWeight: "700",
        fontSize: "28px",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default H3Style;
