import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useGetInfo from '../../../components/BookingWidget/hook/useGetInfo';
import H5Style from '../../../components/textComponents/H5Style';
import PStyle from '../../../components/textComponents/PStyle';
import BookingComponent from './../../../components/BookingWidget/BookingDialog/BookingComponent';
import H1Style from './../../../components/textComponents/H1Style';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link, useNavigate } from 'react-router-dom';
import { convertTime24to12 } from '../../../utils/ConvertTime24to12';
import moment from 'moment';

const dayList = [
  {
    id: 1,
    day: 'Monday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 2,
    day: 'Tuesday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 3,
    day: 'Wednesday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 4,
    day: 'Thursday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 5,
    day: 'Friday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 6,
    day: 'Saturday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 0,
    day: 'Sunday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
];
let newDayList = [...dayList];

const OurCompany = ({ data }) => {
  const { step } = useGetInfo();
  const navigate = useNavigate();

  const [otherLocs, setOtherLocs] = useState([]);
  const [timesheet, setTimesheet] = useState({});
  const [timesheetData, setTimesheetData] = useState(null);

  useEffect(() => {
    if (data) {
      setOtherLocs(data?.otherLocations);
      setTimesheet(data?.timesheet);
      console.log('otherLocs', data?.otherLocations);
      console.log('timesheet', data?.timesheet);
      const splitedFromValues = data?.timesheet?.from.split('-');
      const splitedToValues = data?.timesheet?.to.split('-');
      if (splitedFromValues && splitedToValues) {
        const timesheetObj = newDayList.map((day, i) => {
          if (splitedFromValues[i]?.split('/')[1] !== 'DF') {
            return {
              ...day,
              startTime: convertTime24to12(splitedFromValues[i]?.split('/')[1]),
              endTime: convertTime24to12(splitedToValues[i]?.split('/')[1]),
            };
          } else {
            return {
              ...day,
              startTime: 'day off',
              endTime: 'day off',
              isDayOff:
                splitedFromValues[i]?.split('/')[1] === 'DF' ? true : false,
            };
          }
        });
        setTimesheetData(timesheetObj);
        console.log('timesheetData', timesheetObj);
      }
    }
  }, []);

  return (
    <Box>
      <H1Style
        sx={{
          marginBottom: '20px',
        }}
      >
        About us
      </H1Style>
      <Grid
        className="body__container"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: '30px',
        }}
      >
        <Grid md={4} lg={6} sm={12}>
          <PStyle
            sx={{
              my: 0,
            }}
          >
            {data?.story?.ENG}
          </PStyle>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              marginTop: '40px',
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <img
                src="https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80"
                alt=""
                style={{ width: '100%' }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <img
                src="https://images.unsplash.com/photo-1537047902294-62a40c20a6ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80"
                alt=""
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          md={8}
          lg={6}
          sm={12}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '30px',
          }}
        >
          <Box
            md={6}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '32px',
              flex: '1 1 auto',
            }}
          >
            <Grid>
              <H5Style
                sx={{
                  marginBottom: '15px',
                }}
              >
                Opening here
              </H5Style>
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {timesheetData &&
                  timesheetData.map((day) => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <PStyle sx={{ flex: 1, m: 0 }}>{day.day}</PStyle>
                      <Box
                        sx={{
                          display: 'flex',
                          flex: 1,
                          gap: '10px',
                        }}
                      >
                        <PStyle sx={{ m: 0 }}>
                          {day.startTime === 'day off'
                            ? 'Close'
                            : day.startTime}
                        </PStyle>
                        <PStyle sx={{ m: 0 }}>
                          {day.endTime === 'day off' ? 'Close' : day.endTime}
                        </PStyle>
                      </Box>
                    </Box>
                  ))}
              </Grid>
            </Grid>
            <Grid>
              <H5Style
                sx={{
                  marginBottom: '15px',
                }}
              >
                Holidays
              </H5Style>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {data.holidayId ? (
                  data.holiday.holiday.map((item, i) => {
                    return (
                      <>
                        <PStyle sx={{ m: 0, mr: 1, color: 'red' }}>
                          {moment(item).format('YYYY-MM-DD')}
                          {i === data.holiday.holiday.length - 1 ? '' : ','}
                        </PStyle>
                      </>
                    );
                  })
                ) : (
                  <PStyle sx={{ m: 0, color: '#7b8b98' }}>
                    No Holidays Found
                  </PStyle>
                )}
              </Box>
            </Grid>
            <Grid>
              <H5Style
                sx={{
                  marginBottom: '15px',
                }}
              >
                Contact
              </H5Style>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <EmailIcon
                    sx={{
                      color: '#3E3B4E',
                    }}
                  />
                  <PStyle sx={{ m: 0 }}>{data.email}</PStyle>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <PhoneIcon
                    sx={{
                      color: '#3E3B4E',
                    }}
                  />
                  <PStyle sx={{ m: 0 }}>{data.phone}</PStyle>
                </Box>
              </Box>
            </Grid>
            <Grid>
              <H5Style
                sx={{
                  marginBottom: '15px',
                }}
              >
                Other locations
              </H5Style>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                {data?.otherLocations?.map((loc, i) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: { xs: 'row', sm: 'row', md: 'column' },
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        width: '64px',
                        height: '64px',
                        border: '1px solid #E2E5FC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <LocationOnIcon
                        style={{
                          objectFit: 'cover',
                          color: '#3E3B4E',
                          fontSize: '35px',
                        }}
                        src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                        alt=""
                      />
                    </Box>
                    <Box>
                      <PStyle
                        sx={{ m: 0, fontWeight: 'bold', cursor: 'pointer' }}
                      >
                        <Link
                          target="_blank"
                          to={`/company/${loc.TenantId}/${loc.id}`}
                          style={{
                            color: '#3E3B4E',
                            textDecoration: 'none',
                          }}
                        >
                          {loc.name}
                        </Link>
                      </PStyle>
                      <PStyle sx={{ m: 0 }}>{loc.address}</PStyle>
                      <PStyle sx={{ m: 0 }}>
                        {loc.city}, {loc.country}
                      </PStyle>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Box>
          <Box
            md={6}
            xs={12}
            sx={{
              width: '100%',
              flex: '0 0 350px',
            }}
          >
            <BookingComponent step={step} about={true} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurCompany;
