import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getCategoriesAPI, getDocsAPI } from '../../../services';
import H4Style from '../../textComponents/H4Style';
import classes from './HelpCenterBodySection.module.css';

const HelpCenterBodySection = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [documentations, setDocumentations] = useState([
    { id: 1, label: 'Project Info', link: 'project-info', categoryId: 1 },
    { id: 3, label: 'Installation', link: 'installation', categoryId: 1 },
    { id: 3, label: 'application', link: 'application', categoryId: 1 },
    { id: 3, label: 'applicationv1', link: 'applicationv2', categoryId: 1 },
  ]);

  const getAllDocsFromAPI = useCallback(async () => {
    try {
      const docsResponse = await getDocsAPI();
      const categoriesResponse = await getCategoriesAPI();
      setDocumentations(docsResponse.data);
      setCategories(categoriesResponse.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getAllDocsFromAPI();
  }, [getAllDocsFromAPI]);

  const handleNavigation = (link) => () => navigate(link);

  return (
    <div className={classes.mainContainer}>
      {categories.map((category, index) => {
        const docs = documentations.filter(
          (doc) => parseInt(doc.categoryId) === parseInt(category.id)
        );

        if (docs.length > 0) {
          return (
            <div key={`category-${index}`}>
              <H4Style className={classes.categoryLabel}>
                {category.label}
              </H4Style>
              {docs.map((doc, index) => (
                <div key={`documentation-${index}`}>
                  <p
                    onClick={handleNavigation(`/documentation/${doc.link}`)}
                    className={classes.documentationLink}
                  >
                    {doc.title}
                  </p>
                </div>
              ))}
            </div>
          );
        }
      })}
    </div>
  );
};

export default HelpCenterBodySection;
