import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import CustomTypography from "../CustomTypography";
import H6Style from "../textComponents/H6Style";

function CustomAutocomplete(props) {
  const customData = props.customData;

  return (
    <>
      <H6Style
        sx={{
          // color: "#1976D2 ",
          // fontSize: "16px",
          mb: "5px",
          ...customData?.labelSx,
        }}
      >
        {customData?.label}{" "}
        {props.required ? (
          <span
            style={{
              color: "red",
            }}
          >
            *
          </span>
        ) : (
          ""
        )}
      </H6Style>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.error}
            helperText={
              props.helperText
                ? props.helperText
                : props.error
                ? "This field is required"
                : " "
            }
            {...customData?.textFieldProps}
          />
        )}
        {...props}
        sx={{
          width: "100%",
          "& fieldset": { borderRadius: 1.5 },
          ...props.sx,
        }}
        size="small"
        disablePortal
      ></Autocomplete>
    </>
  );
}

export default CustomAutocomplete;
