import { Button } from '@mui/material';
import React from 'react';

function CustomButton(props) {
  return (
    <Button
      variant="contained"
      size="medium"
      {...props}
      sx={{
        fontWeight: '700',
        boxShadow: 0,
        ':hover': { boxShadow: 0 },
        fontSize: '16px',
        px: '16px',
        ...props.sx,
      }}
    >
      {props.children}
    </Button>
  );
}

export default CustomButton;
