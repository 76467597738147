import {
  Box,
  Button,
  Checkbox,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmProvider, { ConfirmContext } from "./context/confirmProvider";
import Title from "./Title";
import CircularProgress from "@mui/material/CircularProgress";
import useGetInfo from "./hook/useGetInfo";
import axios from "axios";
import { BACKEND_URI_Pay } from "../../constants/endpoint";
import { useParams } from "react-router-dom";

const PaymentOption = ({ stepId }) => {
  const { id } = useParams();

  const [note, setNote] = useState("");
  const { handleConfirmStep } = useGetInfo();

  const [isLoading, setIsLoading] = useState(false);

  // const { confirmState, setConfirmState } = useContext(ConfirmContext);

  // const confirm = useContext(ConfirmContext);
  const navigate = useNavigate();
  const handelConfirm = () => {
    // navigate("/confirm");
    // console.log(confirmState);
    // setConfirmState(true);
    // console.log(confirmState);
  };

  const { infoList } = useGetInfo();

  const addAppointment = () => {
    const date = infoList[4].date;
    const time = infoList[4].time;
    const locationId = infoList[0].option.id;
    const staffId = infoList[1].option.id;
    const serviceId = infoList[2].option.id;
    axios
      .post(
        BACKEND_URI_Pay + "/appointments/add",
        {
          status: "Pending",
          date: date,
          time: time,
          attendees: infoList[5].people,
          note: note,
          categoryId: 1,
          customerId: 1,
          locationId: locationId,
          serviceId: serviceId,
          staffId: staffId,
        },
        {
          headers: {
            TenantId: id,
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    console.log(infoList);
  };

  return (
    <>
      {isLoading ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Title title="Payment" />
          <Box
            sx={{
              padding: "0 31px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: {
                md: "space-between",
                xs: "start",
                sm: "space-between",
              },
              height: { xs: "450px", md: "400px" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  name="gilad"
                  sx={{ padding: "0", margin: "10px 0" }}
                />
                <Typography sx={{ marginX: "10px" }}>
                  I want to recive special offer and news
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox
                  name="gilad"
                  sx={{ padding: "0", margin: "10px 0" }}
                />
                <Typography sx={{ marginX: "10px" }}>
                  I accept the <Link>terms & conditon</Link> of Upnize
                </Typography>
              </Box>
              <TextField
                placeholder="Add information for company and staff"
                multiline
                rows={4}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "11px",
                width: { xs: "100%", md: "47%", sm: "40%" },
                alignItems: "end",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  background: "#6373BC",
                  marginTop: { xs: "1rem", md: "0.2rem" },

                  width: "100%",
                }}
                onClick={handleConfirmStep}
              >
                Stripe
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  background: "#657BEF",
                  marginTop: "1rem",
                  width: "100%",
                }}
                onClick={handleConfirmStep}
              >
                invoice
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  addAppointment();
                  handleConfirmStep();
                }}
                variant="contained"
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  background: "#585858",
                  marginTop: "1rem",
                  width: "100%",
                }}
              >
                Paylocal
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default PaymentOption;
