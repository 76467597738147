import React from "react";
import { Typography } from "@mui/material";

const H5Style = (props) => {
  return (
    <Typography
      {...props}
      sx={{
        color: "#1976D2",
        fontWeight: "700",
        fontSize: "20px",
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
};

export default H5Style;
