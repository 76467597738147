import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import useGetInfo from '../../../components/BookingWidget/hook/useGetInfo';
import H1Style from '../../../components/textComponents/H1Style';

const Reviews = ({ data }) => {
  const { step } = useGetInfo();

  return (
    <Box>
      <Box className="body__container">
        <Grid>
          <H1Style
            sx={{
              marginBottom: '20px',
            }}
          >
            Terms and Conditions
          </H1Style>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
          }}
        >
          <Grid md={6} sm={12}>
            <Box>
              <Typography
                variantMapping="p"
                sx={{ color: 'gray', fontWeight: 'bold', marginBottom: '15px' }}
              >
                {data.tc.ENG}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Reviews;
