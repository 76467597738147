import { Typography } from "@mui/material";
import React from "react";

function CustomTypography(props) {
  return (
    <Typography
      color={"#2D2F3C"}
      lineHeight={"1.2"}
      {...props}
      sx={{
        ...props.sx,
      }}
    >
      {props.children}
    </Typography>
  );
}

export default CustomTypography;
