import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from "@mui/material/DialogTitle";
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import AppLayout from '../AppLayout';
import useGetInfo from '../hook/useGetInfo';
import Options from '../Options';
import BookingComponent from './BookingComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BookingDialog = ({ btnContent = null, isDialog }) => {
  const appBarHeight = '95px';

  const [open, setOpen] = React.useState(false);
  const { step } = useGetInfo();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box onClick={handleClickOpen}>{btnContent}</Box>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        fullWidth
        sx={{
          maxWidth: '850px',
          marginX: 'auto',
          '& .MuiPaper-root': {
            margin: '0',
            width: '100%',
            height: { xs: `calc(100vh - ${appBarHeight})`, sm: 'auto' },
            // top: { xs: `${appBarHeight} !important`, sm: "0 !important" },
            bottom: {
              // xs: `${appBarHeight / 2} !important`,
              xs: `${-47.5}px !important`,
              sm: '0 !important',
            },
            maxHeight: { xs: 'calc(100% - 0px)' },
          },
        }}
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent sx={{ padding: '0', width: '100%' }}>
          {/* <AppLayout>
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Options
                options={step.options}
                stepId={step.id}
                type={step.type}
              />
            </Box>
          </AppLayout> */}
          {open && <BookingComponent step={step} isDialog={true} />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BookingDialog;
