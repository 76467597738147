import { useEffect } from "react";
import classes from "./ChatSupport.module.css";
import chatSupportImg from "../../assets/img/chat-support.jpg";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import H1Style from "../../components/textComponents/H1Style";
import PStyle from "../../components/textComponents/PStyle";

const ChatSupport = () => {
  return (
    <div className={classes.mainContainer}>
      <img className={classes.chatSupportImg} src={chatSupportImg} />
      <H1Style className={classes.title}>
        Welcome to the Chat Support page
      </H1Style>

      <PStyle className={classes.description}>
        Click on the widget that is displayed in the right corner of the website
        to chat with one of our support.
      </PStyle>
      <TawkMessengerReact
        propertyId="63b98eebc2f1ac1e202c389e"
        widgetId="1gm6dci7d"
      />
    </div>
  );
};

export default ChatSupport;
